import { IonInput, IonLabel } from '@ionic/react'
import styles from './SignupField.module.scss'
import {defaultTheme, themeKey} from "../../data/constant";

const SignupField = ({ field, errors }) => {
	const error = errors && errors.filter(e => e.id === field.id)[0]
	const errorMessage = error && errors.filter(e => e.id === field.id)[0].message

	const themeValue = localStorage.getItem(themeKey) || defaultTheme

	return (
		<div className={styles.field}>
			<IonLabel className={`${styles.fieldLabel} ${themeValue === 'dark' ? styles.ionLabelDark : styles.ionLabelLight}`}>
				{field.label}
				{error && <p className="animate__animated animate__bounceIn">{errorMessage}</p>}
			</IonLabel>
			<IonInput className={`${styles.customInput} ${themeValue === 'dark' ? styles.ionInputDark : styles.ionInputLight}`} {...field.input.props} {...field.input.state} />
		</div>
	)
}

export default SignupField
