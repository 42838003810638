import styles from './SystemNotice.module.scss'

import {
	IonButtons,
	IonCol,
	IonContent,
	IonHeader,
	IonPage,
	IonRow,
	IonToolbar,
	IonImg,
	IonCard, IonButton, IonIcon
} from '@ionic/react'
import { chevronBackOutline } from 'ionicons/icons'

import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import {NoticeApi} from '../../request/api'
import BottomLine from '../../components/bottom-line/BottomLine'
import {defaultTheme, themeKey} from "../../data/constant";

const SystemNotice = () => {
	const { t, i18n } = useTranslation('lang')
	const history = useHistory()

	const [systemNotice, setSystemNotice] = useState(t('system-info.noti-content'))

	const themeValue = localStorage.getItem(themeKey) || defaultTheme

	useEffect(() => {
		if (localStorage.getItem('Authorization') === null) history.push('/login')
		NoticeApi().then(res => {
			if (res.code === 200) {
				const { data } = res
				data.map(item => {
					if (item.noticeType === '1') {
						setSystemNotice(item.noticeContent)
					}
				})
			}
		})
	}, [])

	const goBack = () => {
		history.goBack()
	}

	return (
		<IonPage className={styles.systemNoticePage}>
			<IonHeader>
				<IonToolbar>
					<IonRow className="ion-justify-content-between ion-align-items-center">
						<IonCol size="2">
							<IonButtons>
								<IonButton onclick={() => goBack()}>
									<IonIcon style={{color: themeValue === 'dark' ? 'white' :'black'}} slot={'icon-only'} icon={chevronBackOutline}/>
								</IonButton>
							</IonButtons>
						</IonCol>
						<IonCol size="8">
							<h4 className={`main-title ion-text-center ion-no-margin ${themeValue === 'dark' ? styles.systemNoticeHeaderTitleDark : styles.systemNoticeHeaderTitleLight}`}>{t('system-info.title')}</h4>
						</IonCol>
						<IonCol size="2"/>
					</IonRow>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen className="ion-padding">
				<IonCard className={`main-radius ion-no-margin ion-padding ${themeValue === 'dark' ? styles.systemNoticeCardDark : styles.systemNoticeCardLight}`}>
					<IonRow className='text-white ion-align-items-center'>
						<IonImg src="/assets/images/laba@2x.png" /> {t('system-info.notification')}
					</IonRow>
					{/*<p className='ion-text-center text-white'>{t('system-info.noti-title')}</p>*/}
					<p className={`text-white ${styles.noticeText}`} dangerouslySetInnerHTML={{__html : systemNotice}} />
				</IonCard>
			</IonContent>
			<BottomLine />
		</IonPage>
	)
}

export default SystemNotice
