import useAnimateNumber from "use-animate-number";

const AnimateNumber = (props) => {
    const options = {
        duration: 1000,
        enterance: true,
        direct: false,
        disabled: false,
        decimals: 0
    }
    const [value] = useAnimateNumber(props.value, options)
    return (
        <div style={{color: '#ffffff', fontFamily: 'main_number', marginTop: '5px'}}>{value}</div>
    )
}

export default AnimateNumber
