let config = {
    browserTitle: [
        {
            domian: 'visshopee.com',
            title: 'Shopee',
            channelId: 16
        },
        {
            domian: 'viashopee.com',
            title: 'Shopee',
            channelId: 16
        },
        {
            domian: 'vipshopee.com',
            title: 'Shopee',
            channelId: 16
        }
    ]
}

export default config
