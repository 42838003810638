import axios from 'axios'
import {channelIdKey, homeNoticeDialogKey} from "../data/constant";
import {tansParams} from "../data/utils";
import {LogoutApi} from "./api";

// 当前域名
export const currentDomain = window.location.protocol + '//' + window.location.hostname
export const apiUrl = process.env.NODE_ENV === 'development' ? currentDomain + ':9124' : currentDomain + '/v1'

// 配置项
const axiosOption = {
	headers: {
		'Accept-Language': localStorage.getItem('language-ind') || 'vie',
		'Content-Type': 'application/json'
	},
	// baseURL: 'https://sdpweb.shop/v1',
	baseURL: apiUrl,
	// 超时
	timeout: 60000
}

// 创建一个单例
const instance = axios.create(axiosOption)

// 添加请求拦截器
instance.interceptors.request.use((config) => {
		let Authorization = localStorage.getItem('Authorization')
		if (Authorization) {
			config.headers = { ...config.headers, Authorization }
		}

		let lang = localStorage.getItem('language-ind')
		if (lang) {
			config.headers = { ...config.headers, ...{ 'Accept-Language': lang } }
		}

		let channelId = localStorage.getItem(channelIdKey)

		// console.log("请求的语言", lang)
		// console.log('请求的渠道ID------', channelId)

		if (isNaN(Number(channelId))) channelId = null

		config.headers = { ...config.headers, ...{ 'Channel': channelId } }

		// get请求映射params参数
		if (config.method === 'get' && config.params) {
			if (channelId && !config.params.channelId) {
				// 加上渠道号
				config.params.channelId = Number(channelId)
			} else {
				config.params.channelId = null
			}
			let url = config.url + '?' + tansParams(config.params);
			url = url.slice(0, -1);
			config.params = {}
			config.url = url;
		}
		if (config.method === 'post' || config.method === 'put') {
			if (channelId) {
				// 加上渠道号
				if (typeof config.data === 'object' && !config.data.channelId) {
					config.data.channelId = Number(channelId)
				}
			} else {
				if (typeof config.data === 'object' && !config.data.channelId) {
					config.data.channelId = null
				}
			}
		}

		return config
	},
	function (error) {
		// 对请求错误做些什么
		return Promise.reject(error)
	}
)

// 响应拦截器
instance.interceptors.response.use(
	function (response) {
		if (response.data.code === 401) {
			// 登录身份过期
			LogoutApi().then(res => {
				localStorage.removeItem('Authorization')
				localStorage.setItem(homeNoticeDialogKey, 'false')
				window.location.href = '/login'
			})
		}
		return response.data
	},
	function (error) {
		return Promise.reject(error)
	}
)

export default instance
