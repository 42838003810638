import {
	IonButton,
	IonButtons,
	IonCard,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonPage,
	IonRow,
	IonToolbar,
	IonLabel,
	IonItem,
	IonToast,
	IonSelect,
	IonSelectOption,
	useIonRouter
} from '@ionic/react'
import styles from './Withdraw.module.scss'

import SignupField from '../../components/old-signup/SignupField'
import { chevronBackOutline } from 'ionicons/icons'
import { WithdrawField } from '../../data/fields'
import { useEffect, useState } from 'react'
import {changeToStar, getCurrencySymbol, validateForm} from '../../data/utils'
import { useParams, useHistory } from 'react-router'

import { useTranslation } from 'react-i18next'

import { AccountListApi, WithdrawtApi, InfoApi } from '../../request/api'

import { hex_md5 } from '../../data/md5'
import BottomLine from '../../components/bottom-line/BottomLine'
import {defaultTheme, themeKey} from "../../data/constant";

const Withdraw = () => {
	const { t, i18n } = useTranslation('lang')
	const router = useIonRouter()
	const params = useParams()
	const fields = WithdrawField()
	const [errors, setErrors] = useState(false)

	const [assets, setAssets] = useState({})
	const [member, setMember] = useState({})
	const [showToast, setShowToast] = useState(false)
	const [showToast2, setShowToast2] = useState(false)
	const [message, setMessage] = useState('')
	const [accountArr, setAccountArr] = useState([])
	const [AccountSelected, setAccountSelected] = useState(0)

	const themeValue = localStorage.getItem(themeKey) || defaultTheme

	const withdrawAction = () => {
		const errors = validateForm(fields, t('common-text.check-filed-prefix'))
		setErrors(errors)

		if (!errors.length) {
			let data = {
				amount: fields[0].input.state.value,
				memberAccountId: AccountSelected ? Number(AccountSelected) : null,
				tradePassword: hex_md5(fields[1].input.state.value)
			}
			WithdrawtApi(data).then(res => {
				if (res.code === 200) {
					setMessage(res.msg)
					setShowToast(true)
				} else {
					setMessage(res.msg)
					setShowToast2(true)
				}
			})
		}
	}
	const history = useHistory()
	useEffect(() => {
		if (localStorage.getItem('Authorization') === null) history.push('/login')
		InfoApi().then(res => {
			// console.log(res)
			if (res.code === 200) {
				const { member, assets } = res.data
				setMember(member)
				setAssets(assets)
			}
		})
		AccountListApi().then(res => {
			if (res.code === 200) {
				let lang = localStorage.getItem('language-ind')
				if (!lang) lang = 'vie'
				const { data } = res
				data.map(item => {
					if (item.accountType === '1') {
						// const bankNameJson = JSON.parse(item.bankName)
						// item.showName = bankNameJson[lang]
						try {
							const bankNameJson = JSON.parse(item.bankName)
							item.showName = bankNameJson[lang]
						} catch (e) {
							item.showName = item.bankName
						}
					}
					return item
				})
				setAccountArr(res.data)
			}
		})
	}, [])

	useEffect(() => {
		return () => {
			fields.forEach(field => field.input.state.reset(''))
			setErrors(false)
		}
	}, [params])

	const goBack = () => {
		history.goBack()
	}

	return (
		<IonPage className={styles.withdrawPage}>
			<IonHeader className={`${themeValue === 'dark' ? styles.headerDark : styles.headerLight}`}>
				<IonToolbar>
					<IonRow className="ion-justify-content-between ion-align-items-center">
						<IonCol size="2">
							<IonButtons>
								<IonButton onclick={() => goBack()}>
									<IonIcon style={{color: themeValue === 'dark' ? 'white' :'black'}} slot={'icon-only'} icon={chevronBackOutline}/>
								</IonButton>
							</IonButtons>
						</IonCol>
						<IonCol size="8">
							<h4 className={`main-title ion-text-center ion-no-margin ${themeValue === 'dark' ? styles.headerTitleDark : styles.headerTitleLight}`}>{t('withdraw.title')}</h4>
						</IonCol>
						<IonCol size="2"/>
					</IonRow>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen className="ion-padding">
				<IonCard className={`ion-padding main-radius ${themeValue === 'dark' ? styles.ionCardDark : styles.ionCardLight}`}>
					<span>
						{t('withdraw.avaliable-amount')}:
					</span>
					<h2 className="ion-text-center main-number text-white" style={{ color: '#000' }}>
						{getCurrencySymbol()} {typeof assets.availableBalance === 'number' ? assets.availableBalance.toFixed(2) : assets.availableBalance}
					</h2>
					<p className="text-white" style={{ color: '#000' }}>
						<span>{t('withdraw.acc-name')}: </span>
						<span>{member.memberName}</span>
					</p>
					<p className="text-white" style={{ color: '#000' }}>
						<span>{t('withdraw.phone')}: </span>
						<span>{changeToStar(member.phoneNumber, 3, 2)}</span>
					</p>
					<p style={{fontSize: '12px', color: 'red'}}>* {t('withdraw.comment')}</p>
				</IonCard>
				<IonGrid className="ion-padding">
					<IonRow>
						<IonCol size="12" className={styles.fieldGroup}>
							{fields.map((field, index) => {
								return <SignupField field={field} errors={errors} key={index} />
							})}
							<IonItem className={`${styles.selectItem} ${themeValue === 'dark' ? styles.selectItemDark : styles.selectItemLight}`}>
								<IonLabel className={`${themeValue === 'dark' ? styles.ionLabelDark : styles.ionLabelLight}`}>{t('bank.choose-bank')}</IonLabel>
								<IonSelect
									value={AccountSelected}
									mode="ios"
									okText={t('common-text.confirm')}
									cancelText={t('common-text.cancel')}
									onIonChange={e => {
										setAccountSelected(e.detail.value)
									}}
									className={`${themeValue === 'dark' ? styles.selectItemDark : styles.selectItemLight}`}
								>
									{accountArr.map((item, index) => {
										return (
											<IonSelectOption className={`${themeValue === 'dark' ? styles.selectItemDark : styles.selectItemLight}`} value={item.id} key={index}>
												{item.accountType === '1' ? item.showName : item.address}
											</IonSelectOption>
										)
									})}
								</IonSelect>
							</IonItem>
							<IonButton
								className={`custom-button ion-padding-top ion-margin-bottom ${themeValue === 'dark' ? styles.withdrawSubmitBtnDark : styles.withdrawSubmitBtnLight}`}
								expand="block"
								onClick={withdrawAction}
							>
								{t('withdraw.title')}
							</IonButton>
						</IonCol>
						<IonCol/>
					</IonRow>
				</IonGrid>
			</IonContent>
			<BottomLine />
			<IonToast
				isOpen={showToast}
				onDidDismiss={() => {setShowToast(false)}}
				message={message}
				duration={1000}
				position={'top'}
				color="primary"
			/>
			<IonToast
				isOpen={showToast2}
				onDidDismiss={() => {setShowToast2(false)}}
				message={message}
				duration={2000}
				position={'top'}
				color="danger"
			/>
		</IonPage>
	)
}

export default Withdraw
