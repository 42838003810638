import {
	IonCard,
	IonCol,
	IonContent,
	IonHeader,
	IonIcon,
	IonImg,
	IonPage,
	IonRouterLink,
	IonRow,
	IonToolbar,
	IonGrid,
	useIonAlert,
	useIonRouter, IonButtons, IonButton
} from '@ionic/react'
import styles from './Account.module.scss'

import {notifications, chevronForwardOutline} from 'ionicons/icons'

import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import {LogoutApi, InfoApi, LevelApi} from '../../request/api'
import {useHistory, useLocation} from 'react-router'
import {useRaceConditions} from "../../hooks";
import {
	channelIdKey,
	hideInviteCodeChannelIds,
	hideModifyLoginPasswordMenuIds,
	hideTeamReportChannelIds,
	hideAssetsByAccountPageChannelIds,
	homeNoticeDialogKey,
	logoUrlKey,
	noModificationSafePasswordChannelIds,
	showIdTextChannelIds,
	themeKey,
	defaultTheme, showRegPhoneNumberChannelIds, modifySafePasswordNeedAuthChannelIds, hideModifyUserInfoChannelIds
} from "../../data/constant";
import Dialog from "../../components/dialog/Dialog";
import {getCurrencySymbol} from "../../data/utils";
const Account = () => {
	useIonRouter();
	const { pathname } = useLocation()  // 存储当前路由地址 当路由变化时候 重新构建

	const router = useIonRouter()

	const { t, i18n } = useTranslation('lang')
	const [present] = useIonAlert()
	const [assets, setAssets] = useState({})
	const [showTeamReport, setShowTeamReport] = useState(true)
	const [showInviteCode, setShowInviteCode] = useState(true)
	const [showModifyLoginPasswordMenu, setShowModifyLoginPasswordMenu] = useState(true)
	const [member, setMember] = useState({})
	const [imgUrl, setImgUrl] = useState('')
	const [levelArr, setLevelArr] = useState([])
	const [myLevel, setMyLevel] = useState({})
	const [hasSafePassword, setHasSafePassword] = useState(false)
	const [isNoModificationSafePassword, setIsNoModificationSafePassword] = useState(false)
	const [showIdText, setShowIdText] = useState(false)
	const [showRegPhoneNumber, setShowRegPhoneNumber] = useState(false)
	const [showAssets, setShowAssets] = useState(true)

	const [showLogoutDialog, setShowLogoutDialog] = useState(false)

	const [modifySafePasswordNeedAuth, setModifySafePasswordNeedAuth] = useState(false)

	const [showModifyUserInfo, setShowModifyUserInfo] = useState(true)

	const themeValue = localStorage.getItem(themeKey) || defaultTheme

	const history = useHistory()
	useRaceConditions(() => {
		if (localStorage.getItem('Authorization') === null) history.push('/login')

		setImgUrl(localStorage.getItem(logoUrlKey))

		InfoApi().then(res => {
			// console.log(res)
			if (res.code === 200) {
				const { member, assets } = res.data
				console.log(res.data);
				setMember(member)
				setAssets(assets)
				const { hasSafePassword } = res.data.member
				hasSafePassword && setHasSafePassword(hasSafePassword)

				LevelApi().then(res => {
					if (res.code === 200) {
						setLevelArr(res.data)
						if (res.data.length > 0) {
							for (let info of res.data) {
								if (info.id === member.memberLevelId || info.orderNum === member.memberLevelIndex) {
									setMyLevel(info)
									break
								}
							}
						}
					}
				})
			}
		})
	}, [pathname])

	useRaceConditions(() => {
		isCanShowAssets()
		isCanShowUserInfo()
		isCanShowInviteCode()
		isCanShowTeamReport()
		isCanShowModifyLoginPasswordMenu()
		handleIsNoModificationSafePassword()
		isShowIdText()
		isShowShowRegPhoneNumber()
		isModifySafePasswordNeedAuth()
	}, [])

	const isModifySafePasswordNeedAuth = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (modifySafePasswordNeedAuthChannelIds.indexOf(Number(channelId)) > -1) {
			setModifySafePasswordNeedAuth(true)
		} else {
			setModifySafePasswordNeedAuth(false)
		}
	}

	const isCanShowAssets = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (hideAssetsByAccountPageChannelIds.indexOf(Number(channelId)) > -1) {
			setShowAssets(false)
		} else {
			setShowAssets(true)
		}
	}

	const isCanShowUserInfo = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (hideModifyUserInfoChannelIds.indexOf(Number(channelId)) > -1) {
			setShowModifyUserInfo(false)
		} else {
			setShowModifyUserInfo(true)
		}
	}

	const isCanShowModifyLoginPasswordMenu = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (hideModifyLoginPasswordMenuIds.indexOf(Number(channelId)) > -1) {
			setShowModifyLoginPasswordMenu(false)
		} else {
			setShowModifyLoginPasswordMenu(true)
		}
	}

	const handleIsNoModificationSafePassword = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (noModificationSafePasswordChannelIds.indexOf(Number(channelId)) > -1) {
			setIsNoModificationSafePassword(true)
		} else {
			setIsNoModificationSafePassword(false)
		}
	}

	const isCanShowTeamReport = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (hideTeamReportChannelIds.indexOf(Number(channelId)) > -1) {
			setShowTeamReport(false)
		} else {
			setShowTeamReport(true)
		}
	}

	const isCanShowInviteCode = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (hideInviteCodeChannelIds.indexOf(Number(channelId)) > -1) {
			setShowInviteCode(false)
		} else {
			setShowInviteCode(true)
		}
	}

	const isShowIdText = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (showIdTextChannelIds.indexOf(Number(channelId)) > -1) {
			setShowIdText(true)
		} else {
			setShowIdText(false)
		}
	}

	const isShowShowRegPhoneNumber = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (showRegPhoneNumberChannelIds.indexOf(Number(channelId)) > -1) {
			setShowRegPhoneNumber(true)
		} else {
			setShowRegPhoneNumber(false)
		}
	}

	return (
		<IonPage className={styles.accountPage}>
			{showLogoutDialog && (
				<Dialog hideDialog={() => setShowLogoutDialog(!showLogoutDialog)} hide={false}>
					<div style={{ padding: '10px', height: '100px' }}>
						<h3>{t('account.logout-tip')}</h3>
						<div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' , position: 'absolute', bottom: '20px'}}>
							<div
								onClick={() => setShowLogoutDialog(false)}
								style={{ padding: '8px 15px', fontSize: '18px', marginRight: '10px', borderRadius: '30px' }}
								className={`${themeValue === 'dark' ? styles.cancelBtnDark : styles.cancelBtnLight}`}
							>
								{t('account.logout-cancel')}
							</div>
							<div
								onClick={() => {
									LogoutApi().then(res => {
										localStorage.removeItem('Authorization')
										localStorage.setItem(homeNoticeDialogKey, 'false')
										history.replace('/login')
									})}
								}
								style={{
									padding: '8px 15px',
									fontSize: '18px',
									marginLeft: '10px',
									borderRadius: '30px',
									color: '#fff'
								}}
								className={`${themeValue === 'dark' ? styles.okBtnDark : styles.okBtnLight}`}
							>
								{t('account.logout-confirm')}
							</div>
						</div>
					</div>
				</Dialog>
			)}
			<IonHeader className={`${themeValue === 'dark' ? styles.headerDark : styles.headerLight}`}>
				<IonToolbar>
					<IonRow className="ion-justify-content-between ion-align-items-center">
						<IonCol size="2"/>
						<IonCol size="8">
							<h4 className={`main-title ion-text-center ion-no-margin ${themeValue === 'dark' ? styles.headerTitleDark : styles.headerTitleLight}`}>{t('account.title')}</h4>
						</IonCol>
						<IonCol size="2">
							<IonButtons>
								<IonButton onClick={() => {
									router.push('/system-notice')
								}}>
									<IonIcon slot={'icon-only'} icon={notifications}/>
								</IonButton>
							</IonButtons>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen className="ion-padding">
				<IonCard className={`ion-padding main-radius ion-no-margin ${styles.accountHeading} ${themeValue === 'dark' ? styles.topCardDark : styles.topCardLight}`}>
					<IonRow className="ion-justify-content-between">
						<div className={`d-flex ion-align-items-center ${styles.userInfo}`} style={{ display: 'flex' }}>
							<IonImg
								src={member.avatar ? member.avatar : imgUrl}
								className={styles.userAvatar}
								alt="avatar"
							/>
							<div>
								<h5 className="text-white">{member.memberName}</h5>
								{
									showIdText ? <div className="text-gray">ID：{member.id}</div> : <div className="text-gray">UID：{member.id}</div>
								}
								{
									showRegPhoneNumber && <div className="text-gray">{t('common-login-reg.login-type-1')}： <span className="text-gray">{member.phoneNumber}</span> </div>
								}
								{showInviteCode &&
									<div className="d-flex ion-align-items-center" style={{ display: 'flex' }}>
										<div className={`${styles.inviteCodeLabel} 'text-gray`}>{t('account.invite-code')} &nbsp;</div>
										<div className="main-number" style={{ transform: 'translateY(2px)' }}>
											{member.inviteCode}
										</div>
										<IonRouterLink routerLink="/invite">
											<IonImg src="/assets/images/@2x-iv.png" className={styles.inviteImg} alt="invite" />
										</IonRouterLink>
									</div>
								}
							</div>
						</div>
						<IonImg src={myLevel.iconUrl || '/assets/images/personal/badge.png'} className={styles.usreBadge} alt="badge" />
					</IonRow>
					<IonRow className='ion-justify-content-between ion-margin-top ion-padding-top'>
						<div>
							<span>{t('record.account-amount')}:</span>
							<h3 style={{ paddingTop: '8px' }} className="main-number ion-no-margin">{getCurrencySymbol()} {typeof assets.availableBalance === 'number' ? assets.availableBalance.toFixed(2) : assets.availableBalance}</h3>
						</div>
						{showAssets &&
							<div>
								<div
									className="d-flex ion-align-items-center ion-justify-content-end"
								>
									<span className="text-white ion-margin-end">{t('account.enable-amount')}:</span>
									<span className={`main-number ${styles.enableAmount}`} style={{ color: '#fbeabf' }}>
										{getCurrencySymbol()} {typeof assets.availableBalance === 'number' ? assets.availableBalance.toFixed(2) : assets.availableBalance}
									</span>
								</div>
								<div
									className="d-flex ion-align-items-center ion-justify-content-end" style={{ paddingTop: '8px' }}
								>
									<span className="text-white ion-margin-end">{t('account.frozen-amount')}:</span>
									<span className={`main-number`} style={{ color: '#ff6565' }}>
										{getCurrencySymbol()} {typeof assets.lockBalance === 'number' ? assets.lockBalance.toFixed(2) : assets.lockBalance}
									</span>
								</div>
							</div>
						}
					</IonRow>
				</IonCard>
				<IonGrid className={`ion-no-padding ion-margin-top main-radius ${styles.accountItemBox} ${themeValue === 'dark' ? styles.accountItemBoxDark : styles.accountItemBoxLight}`}>
					<IonRouterLink routerLink="/withdraw">
						<IonRow className={`ion-justify-content-between ion-align-items-center ${styles.accountLinkTab}`}>
							<IonCol size="9" className={`ion-text-left ${styles.accountLinkTabTitle}`}>
								<IonRow>
									<IonImg src="/assets/images/personal/3@2x.png" />
									<span className={styles.accountLinkLabel}>{t('account.withdraw-amount')}</span>
								</IonRow>
							</IonCol>
							<IonCol size="3" className={`ion-text-right ${styles.accountLinkTabArrow}`}>
								<IonIcon style={{color: 'white'}} icon={chevronForwardOutline} />
							</IonCol>
						</IonRow>
					</IonRouterLink>
					{showTeamReport &&
						<IonRouterLink routerLink="/team-report">
							<IonRow className={`ion-justify-content-between ion-align-items-center ${styles.accountLinkTab}`}>
								<IonCol size="9" className={`ion-text-left ${styles.accountLinkTabTitle}`}>
									<IonRow>
										<IonImg src="/assets/images/personal/4@2x.png" />
										<span className={styles.accountLinkLabel}>{t('account.t-report')}</span>
									</IonRow>
								</IonCol>
								<IonCol size="3" className={`ion-text-right ${styles.accountLinkTabArrow}`}>
									<IonIcon style={{color: 'white'}} icon={chevronForwardOutline} />
								</IonCol>
							</IonRow>
						</IonRouterLink>
					}
					<IonRouterLink routerLink="/my-account">
						<IonRow className={`ion-justify-content-between ion-align-items-center ${styles.accountLinkTab}`}>
							<IonCol size="9" className={`ion-text-left ${styles.accountLinkTabTitle}`}>
								<IonRow>
									<IonImg src="/assets/images/personal/2@2x.png" />
									<span className={styles.accountLinkLabel}>{t('account.my-account')}</span>
								</IonRow>
							</IonCol>
							<IonCol size="3" className={`ion-text-right ${styles.accountLinkTabArrow}`}>
								<IonIcon style={{color: 'white'}} icon={chevronForwardOutline} />
							</IonCol>
						</IonRow>
					</IonRouterLink>
					<IonRouterLink routerLink="/recharge-record">
						<IonRow className={`ion-justify-content-between ion-align-items-center ${styles.accountLinkTab}`}>
							<IonCol size="9" className={`ion-text-left ${styles.accountLinkTabTitle}`}>
								<IonRow>
									<IonImg src="/assets/images/personal/13@2x.png" />
									<span className={styles.accountLinkLabel}>{t('account.recharge-record')}</span>
								</IonRow>
							</IonCol>
							<IonCol size="3" className={`ion-text-right ${styles.accountLinkTabArrow}`}>
								<IonIcon style={{color: 'white'}} icon={chevronForwardOutline} />
							</IonCol>
						</IonRow>
					</IonRouterLink>
					<IonRouterLink routerLink="/withdraw-record">
						<IonRow className={`ion-justify-content-between ion-align-items-center ${styles.accountLinkTab}`}>
							<IonCol size="9" className={`ion-text-left ${styles.accountLinkTabTitle}`}>
								<IonRow>
									<IonImg src="/assets/images/personal/12@2x.png" />
									<span className={styles.accountLinkLabel}>{t('account.withdraw-record')}</span>
								</IonRow>
							</IonCol>
							<IonCol size="3" className={`ion-text-right ${styles.accountLinkTabArrow}`}>
								<IonIcon style={{color: 'white'}} icon={chevronForwardOutline} />
							</IonCol>
						</IonRow>
					</IonRouterLink>
					<IonRouterLink routerLink="/account-details">
						<IonRow className={`ion-justify-content-between ion-align-items-center ${styles.accountLinkTab}`}>
							<IonCol size="9" className={`ion-text-left ${styles.accountLinkTabTitle}`}>
								<IonRow>
									<IonImg src="/assets/images/personal/5@2x.png" />
									<span className={styles.accountLinkLabel}>{t('account.acc-details')}</span>
								</IonRow>
							</IonCol>
							<IonCol size="3" className={`ion-text-right ${styles.accountLinkTabArrow}`}>
								<IonIcon style={{color: 'white'}} icon={chevronForwardOutline} />
							</IonCol>
						</IonRow>
					</IonRouterLink>
					{/* <IonRouterLink routerLink="/notification">
						<IonRow className={`ion-justify-content-between ion-align-items-center ${styles.accountLinkTab}`}>
							<IonCol size="9" className={`ion-text-left ${styles.accountLinkTabTitle}`}>
								<IonRow>
									<IonImg src="/assets/images/personal/11@2x.png" />
									<span>{t('account.notification')}</span>
								</IonRow>
							</IonCol>
							<IonCol size="3" className={`ion-text-right ${styles.accountLinkTabArrow}`}>
								<IonIcon style={{color: 'white'}} icon={chevronForwardOutline} />
							</IonCol>
						</IonRow>
					</IonRouterLink>
					<IonRouterLink routerLink="/shipping">
						<IonRow className={`ion-justify-content-between ion-align-items-center ${styles.accountLinkTab}`}>
							<IonCol size="9" className={`ion-text-left ${styles.accountLinkTabTitle}`}>
								<IonRow>
									<IonImg src="/assets/images/personal/6@2x.png" />
									<span>{t('account.shipping-address')}</span>
								</IonRow>
							</IonCol>
							<IonCol size="3" className={`ion-text-right ${styles.accountLinkTabArrow}`}>
								<IonIcon style={{color: 'white'}} icon={chevronForwardOutline} />
							</IonCol>
						</IonRow>
					</IonRouterLink> */}
					<IonRouterLink routerLink="/bank">
						<IonRow className={`ion-justify-content-between ion-align-items-center ${styles.accountLinkTab}`}>
							<IonCol size="9" className={`ion-text-left ${styles.accountLinkTabTitle}`}>
								<IonRow>
									<IonImg src="/assets/images/personal/6@2x.png" />
									<span className={styles.accountLinkLabel}>{t('account.bind-bank')}</span>
								</IonRow>
							</IonCol>
							<IonCol size="3" className={`ion-text-right ${styles.accountLinkTabArrow}`}>
								<IonIcon style={{color: 'white'}} icon={chevronForwardOutline} />
							</IonCol>
						</IonRow>
					</IonRouterLink>
					{showModifyUserInfo &&
							<IonRouterLink routerLink="/info">
							<IonRow className={`ion-justify-content-between ion-align-items-center ${styles.accountLinkTab}`}>
								<IonCol size="9" className={`ion-text-left ${styles.accountLinkTabTitle}`}>
									<IonRow>
										<IonImg src="/assets/images/personal/7@2x.png" />
										<span className={styles.accountLinkLabel}>{t('account.change-info')}</span>
									</IonRow>
								</IonCol>
								<IonCol size="3" className={`ion-text-right ${styles.accountLinkTabArrow}`}>
									<IonIcon style={{color: 'white'}} icon={chevronForwardOutline} />
								</IonCol>
							</IonRow>
						</IonRouterLink>
					}
					{(!isNoModificationSafePassword || !hasSafePassword) &&
						<IonRouterLink routerLink={modifySafePasswordNeedAuth ? "/support" : "/password-safe"}>
							<IonRow className={`ion-justify-content-between ion-align-items-center ${styles.accountLinkTab}`}>
								<IonCol size="9" className={`ion-text-left ${styles.accountLinkTabTitle}`}>
									<IonRow>
										<IonImg src="/assets/images/personal/14@2x.png" />
										<span className={styles.accountLinkLabel}>{t('account.password-safe')}</span>
									</IonRow>
								</IonCol>
								<IonCol size="3" className={`ion-text-right ${styles.accountLinkTabArrow}`}>
									<IonIcon style={{color: 'white'}} icon={chevronForwardOutline} />
								</IonCol>
							</IonRow>
						</IonRouterLink>
					}
					{showModifyLoginPasswordMenu &&
						<IonRouterLink routerLink="/password">
							<IonRow className={`ion-justify-content-between ion-align-items-center ${styles.accountLinkTab}`}>
								<IonCol size="9" className={`ion-text-left ${styles.accountLinkTabTitle}`}>
									<IonRow>
										<IonImg src="/assets/images/personal/8@2x.png" />
										<span className={styles.accountLinkLabel}>{t('account.password')}</span>
									</IonRow>
								</IonCol>
								<IonCol size="3" className={`ion-text-right ${styles.accountLinkTabArrow}`}>
									<IonIcon style={{color: 'white'}} icon={chevronForwardOutline} />
								</IonCol>
							</IonRow>
						</IonRouterLink>
					}
					<IonRouterLink routerLink="/language">
						<IonRow className={`ion-justify-content-between ion-align-items-center ${styles.accountLinkTab}`}>
							<IonCol size="9" className={`ion-text-left ${styles.accountLinkTabTitle}`}>
								<IonRow>
									<IonImg src="/assets/images/personal/9@2x.png" />
									<span className={styles.accountLinkLabel}>{t('account.language')}</span>
								</IonRow>
							</IonCol>
							<IonCol size="3" className={`ion-text-right ${styles.accountLinkTabArrow}`}>
								<IonIcon style={{color: 'white'}} icon={chevronForwardOutline} />
							</IonCol>
						</IonRow>
					</IonRouterLink>
					<IonRow
						onClick={() => setShowLogoutDialog(true)}
						className={`ion-justify-content-between ion-align-items-center ${styles.accountLinkTab} ${styles.noBorder}`}
					>
						<IonCol size="9" className={`ion-text-left ${styles.accountLinkTabTitle}`}>
							<IonRow >
								<IonImg src="/assets/images/personal/10@2x.png" />
								<span>{t('account.logout')}</span>
							</IonRow>
						</IonCol>
						<IonCol size="3" className={`ion-text-right ${styles.accountLinkTabArrow}`}>
							<IonIcon style={{color: 'white'}} icon={chevronForwardOutline} />
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	)
}

export default Account
