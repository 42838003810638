import styles from './AccountDetails.module.scss'

import {
	IonButtons,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonPage,
	IonRow,
	IonToolbar,
	IonCard, IonButton, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent
} from '@ionic/react'
import { chevronBackOutline } from 'ionicons/icons'

import {
	channelIdKey,
	hideAccountDetailsTimeChannelIds
} from "../../data/constant";

import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { MemberBillsApi } from '../../request/api'
import BottomLine from '../../components/bottom-line/BottomLine'
import {formatTimeByTimezone, getCurrencySymbol} from "../../data/utils";
import {defaultTheme, themeKey} from "../../data/constant";
import {useRaceConditions} from "../../hooks";

const AccountDetails = () => {
	const { t, i18n } = useTranslation('lang')
	const [dataArr, setDataArr] = useState([])
	const [pageNum, setPageNum] = useState(1)
	const [pageSize, setPageSize] = useState(10)
	const [noDataStatus, setNoDataStatus] = useState(false)
	const history = useHistory()

	const themeValue = localStorage.getItem(themeKey) || defaultTheme

	const [showAccountDetailsTime, setShowAccountDetailsTime] = useState(true)

	useEffect(() => {
		if (localStorage.getItem('Authorization') === null) history.push('/login')
		getMemberBills(pageNum)
	}, [])

	useRaceConditions(() => {
		isCanShowAccountDetailsTime()
	}, [])

	const isCanShowAccountDetailsTime = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (hideAccountDetailsTimeChannelIds.indexOf(Number(channelId)) > -1) {
			setShowAccountDetailsTime(false)
		} else {
			setShowAccountDetailsTime(true)
		}
	}

	const getMemberBills = (pageNum) => {
		MemberBillsApi({pageNum: pageNum, pageSize: pageSize}).then(res => {
			if (res.code === 200) {
				if (res.data.length > 0) {
					if (pageNum === 1) {
						setDataArr(res.data)
					} else {
						setDataArr(dataArr.concat(res.data))
					}
					setNoDataStatus(false)
				} else {
					setNoDataStatus(true)
				}
			}
		})
	}

	const loadData = (event) => {
		setTimeout(() => {
			event.target.complete()
			if (noDataStatus) {
				event.target.disabled = true
			} else {
				setPageNum(pageNum + 1)
				getMemberBills(pageNum + 1)
			}
		}, 500)
	}

	const goBack = () => {
		history.goBack()
	}

	return (
		<IonPage className={styles.accountDetailsPage}>
			<IonHeader className={`${themeValue === 'dark' ? styles.headerDark : styles.headerLight}`}>
				<IonToolbar>
					<IonRow className="ion-justify-content-between ion-align-items-center">
						<IonCol size="2">
							<IonButtons>
								<IonButton onclick={() => goBack()}>
									<IonIcon style={{color: themeValue === 'dark' ? 'white' :'black'}} slot={'icon-only'} icon={chevronBackOutline}/>
								</IonButton>
							</IonButtons>
						</IonCol>
						<IonCol size="8">
							<h4 className={`main-title ion-text-center ion-no-margin ${themeValue === 'dark' ? styles.headerTitleDark : styles.headerTitleLight}`}>{t('account-details.title')}</h4>
						</IonCol>
						<IonCol size="2"/>
					</IonRow>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen className="ion-padding">
				<IonGrid className="ion-padding-start ion-padding-right">
					<p style={{color: themeValue === 'dark' ? 'white' :'black'}}>{t('account-details.title')}</p>
					{dataArr.map((item, index) => {
						return (
							<IonCard className={`ion-no-margin ion-padding main-radius ${themeValue === 'dark' ? styles.ionCardDark : styles.ionCardLight}`} key={index}>
								{showAccountDetailsTime &&
									<IonRow className="ion-justify-content-between ion-align-items-center">
										<span className={styles.date}>{formatTimeByTimezone(item.createTime)}</span>
									</IonRow>
								}
								<IonRow className="ion-justify-content-between ion-align-items-center ion-margin-top">
									<span className={styles.statusBadge}>{t('account-details.bill-type-' + item.billType)}</span>
									<span className={`main-number ${styles.totalAmount}`}>{getCurrencySymbol()} {item.amount.toFixed(2)}</span>
								</IonRow>
							</IonCard>
						)
					})}
					{dataArr.length === 0 ? <h4 style={{ textAlign: 'center', color: 'white' }}>{t('common-text.no-data')}</h4> : null}
				</IonGrid>
				<IonInfiniteScroll threshold="10px" distance="1%" onIonInfinite={(e) => loadData(e)}>
					<IonInfiniteScrollContent loadingSpinner="bubbles" loadingText={t('common-text.load-more-data')}>
					</IonInfiniteScrollContent>
				</IonInfiniteScroll>
			</IonContent>
			<BottomLine />
		</IonPage>
	)
}

export default AccountDetails
