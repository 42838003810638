import {useEffect, useState} from 'react'
import config from './config'
import {channelIdKey, currencyThaiBaht} from "./constant";

export function setBrowserTitle() {
	for (let info of config.browserTitle) {
		if (window.location.host === info.domian) {
			document.title = info.title
			// document.querySelectorAll('meta[property=title]')[0].setAttribute('content', info.title)
			// document.querySelectorAll('meta[property=description]')[0].setAttribute('content', info.title)
		}
	}
}

export function getChanelIdByDomain() {
	for (let info of config.browserTitle) {
		if (window.location.host === info.domian) {
			localStorage.setItem(channelIdKey, info.channelId)
		}
	}
}

export function getCurrencySymbol() {
	const channel = localStorage.getItem(channelIdKey);
	if (currencyThaiBaht.includes(Number(channel))) {
		return '฿'
	}
	return '$'
}

const lang = localStorage.getItem('language-ind') || 'en'

export const useFormInput = (initialValue = '') => {
	const [value, setValue] = useState(initialValue)

	const handleChange = async e => {
		const tempValue = await e.currentTarget.value
		setValue(tempValue)
	}

	useEffect(() => {
		setValue(value)
	}, [value])

	return {
		value,
		reset: newValue => setValue(newValue),
		onIonChange: handleChange,
		onKeyUp: handleChange
	}
}
export const useFormInputBank = (initialValue = '') => {
	const [value, setValue] = useState(initialValue)

	const handleChange = async e => {
		const tempValue = await e.currentTarget.value
		setValue(tempValue)
	}

	useEffect(() => {
		setValue(initialValue)
	}, [initialValue])

	return {
		value,
		reset: newValue => setValue(newValue),
		onIonChange: handleChange,
		onKeyUp: handleChange
	}
}

/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams(params) {
	let result = ''
	for (const propName of Object.keys(params)) {
		const value = params[propName];
		var part = encodeURIComponent(propName) + "=";
		if (value !== null && typeof (value) !== "undefined") {
			if (typeof value === 'object') {
				for (const key of Object.keys(value)) {
					if (value[key] !== null && typeof (value[key]) !== 'undefined') {
						let params = propName + '[' + key + ']';
						var subPart = encodeURIComponent(params) + "=";
						result += subPart + encodeURIComponent(value[key]) + "&";
					}
				}
			} else {
				result += part + encodeURIComponent(value) + "&";
			}
		}
	}
	return result
}

// 设置favicon
export function setFavicon(url) {
	if (!url) return
	let link = document.querySelector("link[rel~='icon']") || document.createElement('link')
	link.rel = 'icon'
	link.href = url
	document.getElementsByTagName('head')[0].appendChild(link)

	//document.querySelectorAll('meta[property=og\\:image]')[0].setAttribute('content', url)
}

// 字符串替换星号
export function changeToStar(str, len1, len2 = 0) {
	if (!str) return ''
	let strLen = str.length;
	let stars = ''
	for (let i = 0; i < strLen - len1 - len2; i++) {
		stars += '*'
	}
	str = str.substr(0, len1) + ' ' + stars + ' ' + str.substr(strLen - len2);
	return str;
}

// 检测邮箱格式是否合法
export function checkEmailFormat(email) {
	const reg = /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/;
	if (reg.test(email)) {
		return true
	} else {
		return false
	}
}

/// 获取客户端当前时区
export const getClientTimezone = () => {
	const oDate = new Date();
	const nTimezone = -oDate.getTimezoneOffset() / 60;
	return nTimezone.toFixed(2);
}

/// 北京时间转换当地时区 2020-20-10 8:19:00
export const formatTimeByTimezone = (t) => {
	//t传入的时间参数,utc传入的时区参数
	if (!t) {
		return false
	} else {
		t = t.replace(/\-/ig, '/')
	}
	//获取本地时间
	const d = new Date();
	//获得本地时区
	let utc = getClientTimezone()
	utc = utc ? parseFloat(utc) : d.getTimezoneOffset() / 60
	//格式化传入时间
	const time = new Date(t);
	time.setHours(time.getHours() + (utc - 8))
	//输出时间
	const yy = time.getFullYear();
	let MM = time.getMonth() + 1;
	MM = MM < 10 ? '0' + MM : MM
	let dd = time.getDate();
	dd = dd < 10 ? '0' + dd : dd
	let hh = time.getHours();
	hh = hh < 10 ? '0' + hh : hh
	let mm = time.getMinutes();
	mm = mm < 10 ? '0' + mm : mm
	let ss = time.getSeconds();
	ss = ss < 10 ? '0' + ss : ss
	return yy + '-' + MM + '-' + dd + ' ' + hh + ':' + mm + ':' + ss
}

/// 北京时间转换当地时区 只要时分秒 8:19:00
export const formatTimeByTimezoneWithHms = (t) => {
	//t传入的时间参数,utc传入的时区参数
	if (!t) {
		return false
	} else {
		t = t.replace(/\-/ig, '/')
	}
	//获取本地时间
	const d = new Date();
	//获得本地时区
	let utc = getClientTimezone()
	utc = utc ? parseFloat(utc) : d.getTimezoneOffset() / 60
	//格式化传入时间
	const time = new Date(t);
	time.setHours(time.getHours() + (utc - 8))
	//输出时间
	let hh = time.getHours();
	hh = hh < 10 ? '0' + hh : hh
	let mm = time.getMinutes();
	mm = mm < 10 ? '0' + mm : mm
	let ss = time.getSeconds();
	ss = ss < 10 ? '0' + ss : ss
	return hh + ':' + mm + ':' + ss
}

/// 北京时间转换当地时区 只要几号
export const formatTimeByTimezoneWithDay = (t) => {
	//t传入的时间参数,utc传入的时区参数
	if (!t) {
		return false
	} else {
		t = t.replace(/\-/ig, '/')
	}
	//获取本地时间
	const d = new Date();
	//获得本地时区
	let utc = getClientTimezone()
	utc = utc ? parseFloat(utc) : d.getTimezoneOffset() / 60
	//格式化传入时间
	const time = new Date(t);
	time.setHours(time.getHours() + (utc - 8))
	//输出时间
	return time.getDate();
}

export const getTimeByHms = () => {
	let myDate = new Date();
	let h = myDate.getHours(); // 获取当前小时数(0-23)
	let m = myDate.getMinutes(); // 获取当前分钟数(0-59)
	let s = myDate.getSeconds();// 获取当前秒数(0-59)
	h = h < 10 ? '0' + h : h
	m = m < 10 ? '0' + m : m
	s = s < 10 ? '0' + s : s
	return h + ':' + m + ':' + s;
}


export const getTimeByDay = () => {
	let myDate = new Date();
	return myDate.getDate();
}

// 将时分秒转为时间戳
function timeToSec(time) {
	if (time !== null) {
		let s;
		const hour = time.split(":")[0];
		const min = time.split(":")[1];
		const sec = time.split(":")[2];
		s = Number(hour * 3600) + Number(min * 60) + Number(sec);
		return s;
	}
}

// 获取当前北京时间
export function getUTC8Date() {
	let d = new Date();
	let currentDate = new Date();
	let tmpHours = currentDate.getHours();
	// //算得时区
	let time_zone = -d.getTimezoneOffset() / 60;
	if (time_zone < 0) {
		time_zone = Math.abs(time_zone) + 8;
		currentDate.setHours(tmpHours + time_zone);
	} else {
		time_zone -= 8;
		currentDate.setHours(tmpHours - time_zone);
	}
	const yy = currentDate.getFullYear();
	let MM = currentDate.getMonth() + 1;
	MM = MM < 10 ? '0' + MM : MM
	let dd = currentDate.getDate();
	dd = dd < 10 ? '0' + dd : dd
	let currHours = currentDate.getHours()
	let currMins = currentDate.getMinutes()
	let currSeconds = currentDate.getSeconds();
	if (currHours < 10) {
		currHours = '0' + currHours
	}
	if (currMins < 10) {
		currMins = '0' + currMins
	}
	if (currSeconds < 10) {
		currSeconds = '0' + currSeconds
	}
	return yy + '-' + MM + '-' + dd + ' ' + currHours + ':' + currMins + ':' + currSeconds;
}

// 如果time2大于time1 返回true 否则 返回false
export const compareTime = (time1, time2) => {
	return timeToSec(time2) - timeToSec(time1) > 0;

}

export const validateForm = (fields, tipPrefix) => {
	let errors = []
	fields.forEach(field => {
		if (field.required) {
			const fieldValue = field.input.state.value
			if (fieldValue === '') {
				const error = {
					id: field.id,
					message:  tipPrefix + `${field.id}`
				}
				errors.push(error)
			}
		}
	})

	return errors
}

export const getIsApp = () => {
	const ua = navigator.userAgent.toLowerCase();
	if (ua.match(/isapp/i) === "isapp") {
		return true;
	}
	return false;
}

export const setShowName = (data, childrenName) => {
	let lang = localStorage.getItem('language-ind')
	if (lang === "") lang = "vie"
	try {
		const showNameJson = JSON.parse(data[`${childrenName}`])
		data.showName = showNameJson[lang]
		return data
	} catch (err) {
		alert(JSON.stringify(err))
		return data[`${childrenName}`]
	}
}

/*判断客户端*/
export const judgeClient = () => {
	let u = navigator.userAgent;
	let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;   //判断是否是 android终端
	let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);     //判断是否是 iOS终端
	// console.log('是否是Android：' + isAndroid); //true,false
	// console.log('是否是iOS：' + isIOS);
	if(isAndroid){
		return 'Android';
	}else if(isIOS){
		return 'IOS';
	}else{
		return 'PC';
	}
}
