import {
	IonButton,
	IonButtons,
	IonCard,
	IonCol,
	IonContent,
	IonDatetime,
	IonGrid,
	IonHeader,
	IonIcon,
	IonImg, IonInfiniteScroll, IonInfiniteScrollContent,
	IonPage,
	IonRow,
	IonToolbar,
} from '@ionic/react'
import styles from './TeamReport.module.scss'

import {chevronBackOutline} from 'ionicons/icons'
import {useTranslation} from 'react-i18next'
import {InfoApi, TeamInfoApi} from '../../request/api'
import {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router'
import BottomLine from '../../components/bottom-line/BottomLine'
import {changeToStar, formatTimeByTimezone, getCurrencySymbol} from "../../data/utils";
import {channelIdKey, defaultTheme, hideTeamSubMembersRegTime, logoUrlKey, themeKey} from "../../data/constant";

function getNowFormatDate(day) {
	const date = new Date()
	if (day) {
		date.setTime(date.getTime() + 24 * day * 60 * 60 * 1000)
	}
	const seperator1 = '-'
	const year = date.getFullYear()
	let month = date.getMonth() + 1
	let strDate = date.getDate()
	if (month >= 1 && month <= 9) {
		month = '0' + month
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = '0' + strDate
	}
	return year + seperator1 + month + seperator1 + strDate
}

const TeamReport = () => {
	const { t, i18n } = useTranslation('lang')
	const [timeActive, setTimeActive] = useState(0)
	const [levelActive, setLevelActive] = useState(0)
	const [start, setStart] = useState('')
	const [end, setEnd] = useState('')
	const [list, setList] = useState([])
	const [memberList, setMemberList] = useState([])
	const [pageNum, setPageNum] = useState(1)
	const [pageSize, setPageSize] = useState(10)
	const [noDataStatus, setNoDataStatus] = useState(false)
	const [assets, setAssets] = useState({})
	const [imgUrl, setImgUrl] = useState('')
	const [showTeamSubMembersRegTime ,setShowTeamSubMembersRegTime] = useState(true)

	const themeValue = localStorage.getItem(themeKey) || defaultTheme

	useEffect(() => {
		getTeamInfo(pageNum, levelActive, '', '', true)
	}, [])

	const history = useHistory()
	useEffect(() => {
		if (localStorage.getItem('Authorization') === null) history.push('/login')
		InfoApi().then(res => {
			if (res.code === 200) {
				setAssets(res.data.assets)
			}
		})
	}, [])

	const getTeamInfo = (pageNum, levelActive, beginTime, endTime, flag = false) => {
		if (flag) {
			beginTime = ''
			endTime = ''
		}
		TeamInfoApi({level: levelActive, beginTime: beginTime, endTime: endTime, pageNum: pageNum, pageSize: pageSize}).then(res => {
			if (res.code === 200) {
				setList(res.data)
				if (res.data.memberList.length > 0) {
					if (pageNum === 1) {
						setMemberList(res.data.memberList)
					} else {
						setMemberList(memberList.concat(res.data.memberList))
					}
					setNoDataStatus(false)
				} else {
					setNoDataStatus(true)
				}
			}
		})
	}

	const loadData = (event) => {
		setTimeout(() => {
			event.target.complete()
			if (noDataStatus) {
				event.target.disabled = true
			} else {
				setPageNum(pageNum + 1)
				getTeamInfo(pageNum + 1, levelActive, start, end)
			}
		}, 500)
	}

	const changeLevel = val => {
		if (levelActive === val) return
		setPageNum(1)
		setNoDataStatus(false)
		setMemberList([])
		setLevelActive(val)
		getTeamInfo(1, val, start, end)
	}

	const changeTime = val => {
		if (timeActive === val) return
		setPageNum(1)
		setNoDataStatus(false)
		setMemberList([])
		switch (val) {
			case 0:
				setTimeActive(val)
				setStart('2022-01-01')
				setEnd(getNowFormatDate())
				getTeamInfo(1, levelActive, '2022-01-01', getNowFormatDate())
				break
			case 1:
				setTimeActive(val)
				setStart(getNowFormatDate())
				setEnd(getNowFormatDate())
				getTeamInfo(1, levelActive, getNowFormatDate(), getNowFormatDate())
				break
			case 2:
				setTimeActive(val)
				setStart(getNowFormatDate(-1))
				setEnd(getNowFormatDate(-1))
				getTeamInfo(1, levelActive, getNowFormatDate(-1), getNowFormatDate(-1))
				break
			case 3:
				setTimeActive(val)
				setStart(getNowFormatDate(-6))
				setEnd(getNowFormatDate())
				getTeamInfo(1, levelActive, getNowFormatDate(-6), getNowFormatDate())
				break
			default:
				setStart('')
				setEnd('')
				break
		}
	}

	useEffect(() => {
		setImgUrl(localStorage.getItem(logoUrlKey))
		isCanShowTeamSubMembersRegTime()
	}, [])

	const goBack = () => {
		history.goBack()
	}

	const isCanShowTeamSubMembersRegTime = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (hideTeamSubMembersRegTime.indexOf(Number(channelId)) > -1) {
			setShowTeamSubMembersRegTime(false)
		} else {
			setShowTeamSubMembersRegTime(true)
		}
	}

	return (
		<IonPage className={styles.teamReportPage}>
			<IonHeader className={`${themeValue === 'dark' ? styles.headerDark : styles.headerLight}`}>
				<IonToolbar>
					<IonRow className="ion-justify-content-between ion-align-items-center">
						<IonCol size="2">
							<IonButtons>
								<IonButton onclick={() => goBack()}>
									<IonIcon style={{color: themeValue === 'dark' ? 'white' :'black'}} slot={'icon-only'} icon={chevronBackOutline}/>
								</IonButton>
							</IonButtons>
						</IonCol>
						<IonCol size="8">
							<h4 className={`main-title ion-text-center ion-no-margin ${themeValue === 'dark' ? styles.headerTitleDark : styles.headerTitleLight}`}>{t('team-report.title')}</h4>
						</IonCol>
						<IonCol size="2" />
					</IonRow>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen className="ion-padding">
				<IonGrid>
					<IonRow className={`${styles.tabBox2} ${styles.tabBoxTitle2}`}>
						<span
							onClick={() => {
								changeLevel(0)
							}}
							className={`${levelActive === 0 ? `${themeValue === 'dark' ? styles.activeDark : styles.activeLight}` : `${themeValue === 'dark' ? styles.noActiveDark : styles.noActiveLight}`}`}
						>
							{t('team-report.all')}
						</span>
					</IonRow>

					<IonRow className={`${styles.tabBox2} ion-margin-start ion-padding-start`}>
						<span
							onClick={() => {
								changeLevel(1)
							}}
							className={`${levelActive == 1 ? `${themeValue === 'dark' ? styles.activeDark : styles.activeLight}` : `${themeValue === 'dark' ? styles.noActiveDark : styles.noActiveLight}`}`}
						>
							{t('team-report.level-1')}
						</span>
						<span
							onClick={() => {
								changeLevel(2)
							}}
							className={`${levelActive == 2 ? `${themeValue === 'dark' ? styles.activeDark : styles.activeLight}` : `${themeValue === 'dark' ? styles.noActiveDark : styles.noActiveLight}`}`}
						>
							{t('team-report.level-2')}
						</span>
						<span
							onClick={() => {
								changeLevel(3)
							}}
							className={`${levelActive == 3 ? `${themeValue === 'dark' ? styles.activeDark : styles.activeLight}` : `${themeValue === 'dark' ? styles.noActiveDark : styles.noActiveLight}`}`}
						>
							{t('team-report.level-3')}
						</span>
					</IonRow>
					<IonRow className={`${styles.tabBox1} ${styles.tabBoxTitle1}`}>
						<span
							onClick={() => {
								changeTime(0)
							}}
							className={`${timeActive == 0 ? `${themeValue === 'dark' ? styles.activeDark : styles.activeLight}` : `${themeValue === 'dark' ? styles.noActiveDark : styles.noActiveLight}`}`}
						>
							{t('team-report.all')}
						</span>
					</IonRow>
					<IonRow className={`${styles.tabBox1} ion-margin-start ion-padding-start`}>
						<span
							onClick={() => {
								changeTime(1)
							}}
							className={`${timeActive == 1 ? `${themeValue === 'dark' ? styles.activeDark : styles.activeLight}` : `${themeValue === 'dark' ? styles.noActiveDark : styles.noActiveLight}`}`}
						>
							{t('team-report.today')}
						</span>
						<span
							onClick={() => {
								changeTime(2)
							}}
							className={`${timeActive == 2 ? `${themeValue === 'dark' ? styles.activeDark : styles.activeLight}` : `${themeValue === 'dark' ? styles.noActiveDark : styles.noActiveLight}`}`}
						>
							{t('team-report.yesterday')}
						</span>
						<span
							onClick={() => {
								changeTime(3)
							}}
							className={`${timeActive == 3 ? `${themeValue === 'dark' ? styles.activeDark : styles.activeLight}` : `${themeValue === 'dark' ? styles.noActiveDark : styles.noActiveLight}`}`}
						>
							{t('team-report.this-week')}
						</span>
					</IonRow>
					<div style={{height: '10px'}}></div>
					{/*<IonRow*/}
					{/*	className={`ion-align-items-center ion-justify-content-around main-radius ion-margin-top ion-margin-bottom ${styles.diplayRange}`}*/}
					{/*>*/}
					{/*	<IonIcon icon={calendarOutline} />*/}
					{/*	<IonDatetime value={start} displayFormat="YYYY-MM-DD"/>*/}
					{/*	<p></p>*/}
					{/*	<IonDatetime value={end} displayFormat="YYYY-MM-DD"/>*/}
					{/*</IonRow>*/}
					<IonCard className={`ion-padding main-radius ion-no-margin ${themeValue === 'dark' ? styles.ionCardDark : styles.ionCardLight}`}>
						<div className="d-flex ion-justify-content-between ion-margin-bottom text-white">
							<span>{t('team-report.all-data')}</span>
							<span className="main-number">{getCurrencySymbol()} {typeof assets.availableBalance === 'number' ? assets.availableBalance.toFixed(2) : assets.availableBalance}</span>
						</div>
						<p className={`${themeValue === 'dark' ? styles.ionCardPDark : styles.ionCardPLight}`}>
							{t('team-report.team-size')}: {list.numberOfMembers}
						</p>
						<p className={`${themeValue === 'dark' ? styles.ionCardPDark : styles.ionCardPLight}`}>
							{t('team-report.team-commission')}: {list.totalRebateAmount ? list.totalRebateAmount.toFixed(2) : '0.00'}
						</p>
					</IonCard>
				</IonGrid>
				<IonGrid className={`${styles.itemBody} ${themeValue === 'dark' ? styles.itemBodyDark : styles.itemBodyLight}`}>
					{memberList &&
						memberList.map((item, index) => {
							return (
								<div
									className={`d-flex ion-align-items-center ${styles.dataItem}`}
									key={index}
									style={{ display: 'flex' }}
								>
									<IonCol size="2" style={{ marginLeft: '10px' }}>
										<IonImg src={item.avatar ? item.avatar : imgUrl} />
									</IonCol>
									<div>
										<p className={styles.userName}>
											{t('team-report.user-name')}: {item.memberName}
										</p>
									</div>
									<div>
										<p style={{color: themeValue === 'dark' ? '#b1b5b8' : '#ebeff1'}}>
											{t('team-report.phone')}: {changeToStar(item.phoneNumber, 3, 2)}
										</p>
										{showTeamSubMembersRegTime &&
											<p style={{color: themeValue === 'dark' ? '#b1b5b8' : '#ebeff1'}}>
												{t('team-report.register-time')}: {formatTimeByTimezone(item.createTime)}
											</p>
										}
									</div>
								</div>
							)
						})}
					{memberList?.length === 0 ? <h4 style={{ textAlign: 'center', color: 'white' }}>{t('common-text.no-data')}</h4> : null}
				</IonGrid>
				<IonInfiniteScroll threshold="10px" distance="1%" onIonInfinite={(e) => loadData(e)}>
					<IonInfiniteScrollContent loadingSpinner="bubbles" loadingText={t('common-text.load-more-data')}>
					</IonInfiniteScrollContent>
				</IonInfiniteScroll>
			</IonContent>
			<BottomLine />
		</IonPage >
	)
}

export default TeamReport
