import request from './request'

export const ChannelApi = () => request({
    url: '/app/channel',
    method: 'get'
})

/*
 * Member module
 */
export const RegisterApi = data => request({
    url: '/app/member/register',
    method: 'post',
    data: data
})

export const LoginApi = data => request({
    url: '/app/member/login',
    method: 'post',
    data: data
})

export const InfoApi = () => request({
    url: '/app/member/info',
    method: 'get'
})

export const LogoutApi = () => request({
    url: '/app/member/logout',
    method: 'post'
})

export const MessageApi = () => request({
    url: '/app/member/message',
    method: 'get'
})

export const AccountListApi = () => request({
    url: '/app/member/account/list',
    method: 'get'
})

export const AccountInfoApi = params => request({
    url: '/app/member/account/info',
    method: 'get',
    params: params
})

export const ModifyPasswordApi = data => request({
    url: '/app/member/login/password/modify',
    method: 'post',
    data: data
})

export const ModifySafePasswordApi = data => request({
    url: '/app/member/safe/password/modify',
    method: 'post',
    data: data
})

export const ModifyAvatarApi = data => request({
    url: '/app/member/avatar/modify',
    method: 'post',
    data: data
})

export const InfoModifyApi = data => request({
    url: '/app/member/modify',
    method: 'post',
    data: data
})

export const AddAccountApi = data => request({
    url: '/app/member/account/add',
    method: 'post',
    data: data
})

export const ModifyAccountApi = data => request({
    url: '/app/member/account/modify',
    method: 'post',
    data: data
})

export const WithdrawtApi = data => request({
    url: '/app/member/withdraw',
    method: 'post',
    data: data
})

/*
 * Resource module
 */

export const CarouselImageApi = () => request({
    url: '/app/common/manage/image/carousel',
    method: 'get',
    params: {}
})

export const LinksImageApi = () => request({
    url: '/app/common/manage/image/links',
    method: 'get',
    params: {}
})

export const BaseImageApi = () => request({
    url: '/app/common/manage/image/base',
    method: 'get',
    params: {}
})

export const HomeIntroduceApi = () => request({
    url: '/app/common/manage/graphic/home',
    method: 'get',
    params: {}
})

export const HomePlatformApi = () => request({
    url: '/app/common/manage/graphic/home/platform',
    method: 'get',
    params: {}
})

export const HomeRulesApi = () => request({
    url: '/app/common/manage/graphic/home/rules',
    method: 'get',
    params: {}
})

export const HomeDirectDisplayApi = () => request({
    url: '/app/common/manage/graphic/home/direct/display',
    method: 'get',
    params: {}
})

export const HomeIntroduceInfoApi = params => request({
    url: '/app/common/manage/graphic/info',
    method: 'get',
    params: params
})

export const BusinessBankApi = () => request({
    url: '/app/common/bank/business',
    method: 'get',
    params: {}
})

export const LangApi = () => request({
    url: '/app/common/support/lang',
    method: 'get'
})

export const AreaCodeApi = () => request({
    url: '/app/common/area/code',
    method: 'get'
})

export const AreaCodeInfoApi = params => request({
    url: '/app/common/area/code/info',
    method: 'get',
    params: params
})

export const ServiceApi = () => request({
    url: '/app/common/customer/service',
    method: 'get',
    params: {}
})

export const NoticeApi = () => request({
    url: '/app/common/global/notice',
    method: 'get',
    params: {}
})

export const LevelApi = () => request({
    url: '/app/common/member/level/config',
    method: 'get',
    params: {}
})

export const displayIncomeApi = () => request({
    url: '/app/common/income/display',
    method: 'get',
    params: {}
})

export const getLastGrabOrderInfo = () => request({
    url: '/app/member/order/grab/last/info',
    method: 'get',
    params: {}
})

/*
 * Order module
 */
export const GrabOrderApi = () => request({
    url: '/app/member/order/grab',
    method: 'post'
})

export const SubmitOrderApi = data => request({
    url: '/app/member/order/submit',
    method: 'post',
    data: data
})

export const PresetOrderApi = () => request({
    url: '/app/member/order/goods/preset',
    method: 'get'
})

/*
 * Record module
 */

export const CashRecordApi = params => request({
    url: '/app/record/cash',
    method: 'get',
    params: params
})

export const MemberBillsApi = params => request({
    url: '/app/report/member/bills',
    method: 'get',
    params: params
})

export const GetGrabOrderApi = params => request({
    url: '/app/record/order/grab',
    method: 'get',
    params: params
})

export const TeamInfoApi = params => request({
    url: '/app/agent/team/info',
    method: 'get',
    params: params
})
