import {useEffect} from "react";


/**
 *  自定义 hooks
 *
 * @param fetchFn
 * @param deps
 */

export function useRaceConditions(fetchFn, deps) {
    useEffect(() => {
        let isCurrent = true
        const cleanEffect = fetchFn(() => isCurrent)
        return () => {
            isCurrent = !isCurrent
            // 如果 fetchFn 返回了函数，则在清除时执行
            cleanEffect && cleanEffect()
        }
    }, deps)
}
