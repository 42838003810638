import { IonImg } from '@ionic/react'
import React, { Component } from 'react'
import styles from './Slogan.module.scss'

export default class Slogan extends Component {
	constructor(props) {
		super(props)
		this.state = {
			sloganList: this.props.test,
			sloganIndex: 0
		}
		this.sign = true //判断是否进行文字滚动动画
		this.textWidth = 0 //文字的宽度
		this.textLeft = 0
		this.textLeftTmp = 0
		this.offsetStep = 0.3 //每次移动的步长
	}

	componentDidMount() {
		let sloganContainer = this.container.clientWidth;
		this.text.style.left = sloganContainer + "px";
		this.timer = setInterval(() => {
			if (this.sign) {
				if(this.props.test && this.props.test.length) {
					this.showSlogan();
				} else {
					clearInterval(this.timer);
				}
			} else {
				this.timer = null;
			}
		}, 1000);
	}

	componentWillUnmount() {
		clearInterval(this.timer)
	}

	render() {
		const { sloganIndex } = this.state
		const { themeValue } = this.props
		return (
			<div ref={ref => (this.container = ref)} className={`${styles.container} ${themeValue === 'dark' ? styles.containerDark : styles.containerLight}`}>
				<div className={`${styles.imgBox}`}>
					<IonImg src='/assets/images/laba@2x.png' />
				</div>
				<span ref={(ref) => this.text = ref} className={`${styles.slogan}`}>
                    {this.props.test[sloganIndex]}
                </span>
			</div>
		)
	}

	showSlogan = () => {
		this.textWidth = this.text.clientWidth;//文字宽度
		// this.textLeft = parseInt(this.text.style.left);//相对父元素偏移距离
		if(this.textLeftTmp >= -this.textWidth) {
			this.sign = false;
		}
		if(this.textLeftTmp > -this.textWidth) {
			this.text.style.left = this.textLeftTmp - this.offsetStep + "px";
			this.textLeftTmp = this.textLeftTmp - this.offsetStep
			requestAnimationFrame(this.showSlogan)
		} else {
			let nextIndex = this.state.sloganIndex !== this.props.test.length - 1 ? ++this.state.sloganIndex : 0;
			this.setState({
				sloganIndex: nextIndex
			}, () => {
				this.text.style.left = this.container.clientWidth + "px";
				this.textLeftTmp = this.container.clientWidth
				this.textWidth = this.text.clientWidth;
				this.sign = true;
			})
		}
	}
}
