import {
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonPage,
	IonRow,
	IonToolbar,
} from '@ionic/react'
import styles from './Shipping.module.scss'

import SignupField from '../../components/old-signup/SignupField'
import { chevronBackOutline } from 'ionicons/icons'
import { ShippingAddress } from '../../data/fields'
import { useEffect, useState } from 'react'
import { validateForm } from '../../data/utils'
import { useParams, useHistory } from 'react-router'
import { useIonRouter } from '@ionic/react'

import { useTranslation } from 'react-i18next'
import BottomLine from '../../components/bottom-line/BottomLine'
import {defaultTheme, themeKey} from "../../data/constant";

const Shipping = () => {
	const { t, i18n } = useTranslation('lang')
	const router = useIonRouter()
	const params = useParams()
	const fields = ShippingAddress()
	const [errors, setErrors] = useState(false)

	const history = useHistory()

	const themeValue = localStorage.getItem(themeKey) || defaultTheme

	useEffect(() => {
		if (localStorage.getItem('Authorization') === null) history.push('/login')
	}, [])

	const changeAddress = () => {
		const errors = validateForm(fields, t('common-text.check-filed-prefix'))
		setErrors(errors)

		if (!errors.length) {
			router.push('/login')
			//  Submit your form here
		}
	}

	useEffect(() => {
		return () => {
			fields.forEach(field => field.input.state.reset(''))
			setErrors(false)
		}
	}, [params])

	const goBack = () => {
		history.goBack()
	}

	return (
		<IonPage className={styles.signupPage}>
			<IonHeader className={`${themeValue === 'dark' ? styles.headerDark : styles.headerLight}`}>
				<IonToolbar>
					<IonRow className="ion-justify-content-between ion-align-items-center">
						<IonCol size="2">
							<IonButtons>
								<IonButton onclick={() => goBack()}>
									<IonIcon style={{color: themeValue === 'dark' ? 'white' :'black'}} slot={'icon-only'} icon={chevronBackOutline}/>
								</IonButton>
							</IonButtons>
						</IonCol>
						<IonCol size="8">
							<h4 className={`main-title ion-text-center ion-no-margin ${themeValue === 'dark' ? styles.headerTitleDark : styles.headerTitleLight}`}>{t('shipping.title')}</h4>
						</IonCol>
						<IonCol size="2"></IonCol>
					</IonRow>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen className="ion-padding">
				<IonGrid className="ion-padding">
					<IonRow>
						<IonCol size="12">
							{fields.map((field, index) => {
								return <SignupField field={field} errors={errors} key={index} />
							})}
							<IonRow className={`ion-justify-content-between ion-padding-top ${styles.setAddress}`}>
								<span>{t('shipping.common-add')}</span>
								<label className={`${styles.togglerWrapper} ${styles.customCheck}`}>
									<input type="checkbox" />
									<div className={styles.togglerSlider}>
										<div className={styles.togglerKnob}></div>
									</div>
								</label>
							</IonRow>
							<IonButton
								className="custom-button ion-padding-top ion-margin-bottom"
								expand="block"
								onClick={changeAddress}
							>
								{t('shipping.confirm')}
							</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
			<BottomLine />
		</IonPage>
	)
}

export default Shipping
