import React, { Component } from 'react'
import { createPortal } from 'react-dom'
import './DialogNotice.scss'
import {defaultTheme, themeKey} from "../../data/constant";
export default class Dialog extends Component {
	constructor(props) {
		super(props)
		const doc = window.document
		this.node = doc.createElement('div')
		doc.body.appendChild(this.node)
	}
	componentWillUnmount() {
		window.document.body.removeChild(this.node)
	}
	render() {
		const { children, hide } = this.props
		let tem = hide ? 'hidden' : ''
		const themeValue = localStorage.getItem(themeKey) || defaultTheme
		// console.log('hide', tem)
		return createPortal(
			<div className="dialogBox" style={{ visibility: tem }}>
				<div className={`dialog ${themeValue === 'dark' ? 'dialogDark' : 'dialogLight'}`}>{children}</div>
			</div>,
			this.node
		)
	}
}
