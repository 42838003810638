import React, { Component } from 'react'
import {
	IonCol,
	IonHeader,
	IonPage,
	IonRow,
	IonToolbar,
	IonContent
} from '@ionic/react'
import './style.css'
import styles from "../../pages/support/Support.module.scss";

class Pager extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isTouching: false,
			lastChar: 'A',
			searchText: '',
			countries: []
		}
		this.charStr = '*ABCDEFGHIJKLMNOPQRSTUVWXYZ#'
		this.boxClientTop = 50
		this.touchStart = this.touchStart.bind(this)
		this.touchMove = this.touchMove.bind(this)
		this.touchEnd = this.touchEnd.bind(this)
		this.getChar = this.getChar.bind(this)
		this.gotoChar = this.gotoChar.bind(this)

	}
	componentDidMount() {

	}

	touchStart(e) {
		this.setState({ isTouching: true })
		const char = this.getChar(e.touches[0].clientY)
		this.gotoChar(char)
	}
	touchMove(e) {
		e.preventDefault()
		const char = this.getChar(e.touches[0].clientY)
		this.gotoChar(char)
	}
	touchEnd(e) {
		e.preventDefault()
		this.setState({ isTouching: false })
	}
	getChar(clientY) {
		const charHeight = this.refs.charBar.offsetHeight / this.charStr.length
		const index = Math.floor((clientY - this.boxClientTop) / charHeight)
		return this.charStr[index]
	}
	gotoChar(char) {
		if (char === this.lastChar) {
			return false
		}
		this.setState({ lastChar: char })
		if (char === '*') {
			this.refs.countryList.scrollTop = 0
		} else if (char === '#') {
			this.refs.countryList.scrollTop = this.refs.countryList.scrollHeight
		}
		const target = document.querySelector('[data-shortname="' + char + '"]')
		target && target.scrollIntoView()
	}
	componentWillMount() {

	}
	render() {
		const {title, data, hideDialog, themeValue } = this.props
		return (
			<IonPage className={`${styles.recordPage} ${themeValue === 'dark' ? 'pageBgDark' : 'pageBgLight'}`}>
				<IonHeader>
					<IonToolbar>
						<IonRow className="ion-justify-content-between ion-align-items-center">
							<IonCol size="2">
								<div onClick={() => {
									hideDialog()
								}} style={{fontSize: '21px'}}>X</div>
							</IonCol>
							<IonCol size="8">
								<h4 className="main-title ion-text-center ion-no-margin">{title}</h4>
							</IonCol>
							<IonCol size="2" />
						</IonRow>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<div className={`country-border ${themeValue === 'dark' ? 'pageBgDark' : 'pageBgLight'}`} ref="countryList">
						{data.map((item, index) => {
							return (
								<div key={index}>
									{!item.first && <div data-shortname={item.shortName.slice(0, 1)}></div>}
									<div
										className="item"
										onClick={() => {
											hideDialog(item.areaCode)
										}}
									>
										<div>{item.showName}</div>
										<div style={{paddingLeft: '5px'}}>(+{item.areaCode})</div>
									</div>
								</div>
							)
						})}
						<div className="char-list-border">
							<ul
								className="char-list"
								ref="charBar"
								onTouchStart={this.touchStart}
								onTouchMove={this.touchMove}
								onTouchEnd={this.touchEnd}
							>
								{this.charStr.split('').map((char, index) => {
									return (
										<li className="char-item" key={index}>
											{char}
										</li>
									)
								})}
							</ul>
						</div>
						{this.state.isTouching && <div className="char-tip">{this.state.lastChar}</div>}
					</div>
				</IonContent>
			</IonPage>
		)
	}
}

export default Pager
