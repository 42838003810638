import {
	IonButtons,
	IonCol,
	IonHeader,
	IonPage,
	IonRow,
	IonToolbar,
	IonContent,
	IonCard, IonButton, IonIcon,
} from '@ionic/react'
import styles from './CompanyProfile.module.scss'

import { chevronBackOutline } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import BottomLine from '../../components/bottom-line/BottomLine'
import { useHistory } from 'react-router-dom'
import {HomePlatformApi} from '../../request/api'
import { useEffect, useState } from 'react'
import {defaultTheme, themeKey} from "../../data/constant";

const CompanyProfile = () => {
	const { t, i18n } = useTranslation('lang')
	const history = useHistory()
	const [introduceContent, setIntroduceContent] = useState([])

	const themeValue = localStorage.getItem(themeKey) || defaultTheme

	useEffect(() => {
		if (localStorage.getItem('Authorization') === null) history.push('/login')
	}, [])

	useEffect(() => {
		HomePlatformApi().then(res => {
			if (res.code === 200) {
				// console.log(res.data[2].graphicTitle)
				if(res.data.length > 0) {
					for (let i = 0; i < res.data.length; i++) {
						if (res.data[i].graphicType === '7') {
							setIntroduceContent(res.data[i].graphicContent)
							break
						}
					}

				}
			}
		})
	}, [])

	const goBack = () => {
		history.goBack()
	}

	return (
		<IonPage className={styles.companyPage}>
			<IonHeader className={`${themeValue === 'dark' ? styles.headerDark : styles.headerLight}`}>
				<IonToolbar>
					<IonRow className="ion-justify-content-between ion-align-items-center">
						<IonCol size="2">
							<IonButtons>
								<IonButton onclick={() => goBack()}>
									<IonIcon style={{color: themeValue === 'dark' ? 'white' :'black'}} slot={'icon-only'} icon={chevronBackOutline}/>
								</IonButton>
							</IonButtons>
						</IonCol>
						<IonCol size="8">
							<h4 className={`main-title ion-text-center ion-no-margin ${themeValue === 'dark' ? styles.headerTitleDark : styles.headerTitleLight}`}>{t('home.introduction.company-profile')}</h4>
						</IonCol>
						<IonCol size="2"></IonCol>
					</IonRow>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonCard className={`ion-padding main-radius ion-margin-start ion-margin-end ${themeValue === 'dark' ? styles.ionCardDark : styles.ionCardLight}`}>
					<span dangerouslySetInnerHTML={{ __html: introduceContent }}></span>
				</IonCard>
			</IonContent>
			<BottomLine />
		</IonPage>
	)
}

export default CompanyProfile
