import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonPage,
    IonRouterLink,
    IonRow,
    IonToolbar,
    IonToast,
    IonInput, useIonLoading, IonLabel, IonSegment, IonSegmentButton
} from '@ionic/react'
import styles from './Login.module.scss'
import {globeOutline} from 'ionicons/icons'
import DialogSide from '../../components/dialog-side/DialogSide'
import {useEffect, useState} from 'react'
import {useHistory, useLocation, useParams} from 'react-router'

import {useTranslation} from 'react-i18next'

import {LoginApi, AreaCodeInfoApi, AreaCodeApi, ChannelApi, BaseImageApi} from '../../request/api'

import {hex_md5} from '../../data/md5'
import BottomLine from '../../components/bottom-line/BottomLine'
import {
    appDownloadLinkKey,
    channelIdKey, defaultTheme,
    grabOrderAnimationTimeKey,
    hideAddTradeAccountBtnKey,
    hideCustomerServiceByLoginPage,
    hideEditTradeAccountBtnKey,
    homeNoticeDialogKey,
    logoUrlKey, themeKey
} from "../../data/constant";
import i18next from "../../i18n";
import {setFavicon} from "../../data/utils";

const Login = () => {
    const {t, i18n} = useTranslation('lang')
    const [present, dismiss] = useIonLoading();
    const history = useHistory()
    const params = useParams()

    const { pathname } = useLocation()  // 存储当前路由地址 当路由变化时候 重新构建

    const [errors, setErrors] = useState(false)

    const [showCustomerService, setShowCustomerService] = useState(true)

    const [showToast, setShowToast] = useState(false)
    const [showToast2, setShowToast2] = useState(false)
    const [message, setMessage] = useState('')

    const [imgUrl, setImgUrl] = useState('')

    const [fromType, setFromType] = useState(1)

    const [countries, setCountries] = useState([])

    const [codeData, setCodeData] = useState({
        areaCode: '84',
        iconUrl: ''
    })

    const [loginName, setLoginName] = useState('')
    const [loginPassword, setLoginPassword] = useState('')
    const [email, setEmail] = useState('')

    const [showDialog, setShowDialog] = useState(false)

    const themeValue = localStorage.getItem(themeKey) || defaultTheme

    useEffect(() => {
        if (localStorage.getItem('Authorization') != null) {
            if (pathname === '/login') {
                history.replace('/app/home')
            }
        }
        setImgUrl(localStorage.getItem(logoUrlKey))
    }, [pathname])

    useEffect(() => {
        if (pathname !== '/login') {
            return
        }
        localStorage.setItem(homeNoticeDialogKey, 'false')
    }, [pathname])

    const getAreaCodeInfo = (areaCode) => {
        AreaCodeInfoApi({areaCode: areaCode}).then(res => {
            if (res.code === 200) {
                const {areaCode, iconUrl} = res.data
                setCodeData({areaCode, iconUrl})
            }
        })
    }

    const getChannelInfo = () => {
        ChannelApi().then(res => {
            if (res.code === 200) {
                localStorage.setItem(channelIdKey, res.channelId)
                if (res.configs.length > 0) {
                    for (let info of res.configs) {
                        if (info.configKey === 'grab.order.animation.time') {
                            localStorage.setItem(grabOrderAnimationTimeKey, info.configValue)
                        }
                        if (info.configKey === 'app.website.name') {
                            document.title = info.configValue
                        }
                        if (info.configKey === 'app.download.link') {
                            localStorage.setItem(appDownloadLinkKey, info.configValue)
                        }
                        if (info.configKey === 'hide.add.trade.account') {
                            localStorage.setItem(hideAddTradeAccountBtnKey, info.configValue)
                        }
                        if (info.configKey === 'hide.edit.trade.account') {
                            localStorage.setItem(hideEditTradeAccountBtnKey, info.configValue)
                        }

                        if (info.configKey === 'app.default.language') {
                            const value = localStorage.getItem('language-ind')
                            if (!value) {
                                localStorage.setItem('language-ind', info.configValue)
                            }
                            i18next.changeLanguage(value || info.configValue).then(r => {})
                        }

                        if (info.configKey === 'app.default.area.code') {
                            getAreaCodeInfo(info.configValue)
                        }
                    }
                }
                BaseImageApi().then(res => {
                    if (res.code === 200) {
                        if (res.data.length > 0) {
                            for (let info of res.data) {
                                if (info.obType === '2') {
                                    localStorage.setItem(logoUrlKey, info.imgUrls)
                                    setImgUrl(info.imgUrls)
                                }
                                if (info.obType === '6') {
                                    setFavicon(info.imgUrls)
                                }
                            }
                        }
                    }
                })
            } else {
                localStorage.setItem(channelIdKey, null)
            }
        }).catch(r => {
            localStorage.setItem(channelIdKey, null)
        })
    }

    const login = () => {
        setErrors(errors)
        if (!errors.length) {
            let data = {
                loginType: fromType,
                areaCode: codeData.areaCode,
                loginName: loginName || null,
                email: email || null,
                password: hex_md5(loginPassword.trim()),
            }
            LoginApi(data).then(res => {
                if (res.code === 200) {
                    localStorage.setItem('Authorization', res.data['token'])
                    setShowToast(true)
                    history.replace('/app/home')
                } else {
                    setMessage(res.msg)
                    setShowToast2(true)
                }
            })
        }
    }

    const openDialog = (status) => {
        present().then(_ => {})
        AreaCodeApi().then(res => {
            dismiss().then(_ => {})
            if (res.code === 200) {
                const { data } = res
                let lang = localStorage.getItem('language-ind')
                data.map(item => {
                    let areaNameJson = JSON.parse(item.areaName)
                    item.showName = areaNameJson[lang]
                    return item
                })
                setCountries(data)
                setShowDialog(status)
            }
        })
    }

    const hideDialog = val => {
        if (!val) {
            setShowDialog(false)
            return
        }
        getAreaCodeInfo(val)
        setShowDialog(false)
    }

    useEffect(() => {
        //getAreaCodeInfo(codeData.areaCode)
        getChannelInfo()
    }, [])

    useEffect(() => {
        return () => {
            setLoginName('')
            setEmail('')
            setLoginPassword('')
            setErrors(false)
        }
    }, [params])

    const onChangeSegment = (e) => {
        setFromType(Number(e.detail.value))
    }

    useEffect(() => {
        isCanShowCustomerService()
    }, [])

    const isCanShowCustomerService = () => {
        let channelId = localStorage.getItem(channelIdKey)
        if (hideCustomerServiceByLoginPage.indexOf(Number(channelId)) > -1) {
            setShowCustomerService(false)
        } else {
            setShowCustomerService(true)
        }
    }

    return (
        <IonPage className={`${styles.loginPage} ${themeValue === 'dark' ? styles.loginPageDark : styles.loginPageLight}`}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start" />
                    <IonButtons slot="end">
                        <IonRouterLink routerLink="/language">
                            <IonButton>
                                <IonIcon style={{color: themeValue === 'dark' ? 'white' :'black'}} icon={globeOutline}/>
                            </IonButton>
                        </IonRouterLink>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            {showDialog &&
                <DialogSide hideDialog={hideDialog} hide={false} title={t('common-text.area-code')} data={countries} />}
            <IonContent fullscreen className="ion-padding">
                <IonGrid className="ion-padding">
                    <IonRow className="ion-justify-content-center ion-padding-top">
                        <IonImg src={imgUrl || '/assets/icon/logo.png'}/>
                    </IonRow>

                    <IonRow style={{marginTop: '10px'}}>
                        <IonSegment className={`${themeValue === 'dark' ? styles.ionSegmentDark : styles.ionSegmentLight}`} value={fromType} onIonChange={onChangeSegment}>
                            <IonSegmentButton value="1">
                                <IonLabel style={{color: themeValue === 'dark' ? fromType === 1 ? '#ffffff' : '#ccc' : fromType === 1 ? 'black' : '#ccc', fontSize: '15px'}}>{t('common-login-reg.login-type-1')}</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="2">
                                <IonLabel style={{color: themeValue === 'dark' ? fromType === 2 ? '#ffffff' : '#ccc' : fromType === 2 ? 'black' : '#ccc', fontSize: '15px'}}>{t('common-login-reg.login-type-2')}</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                    </IonRow>

                    <IonRow className="ion-margin-top ion-padding-top">
                        <IonCol size="12" className="ion-margin-top ion-padding-top">
                            {fromType === 1 ?
                                <div className={`${styles.formItem} ${themeValue === 'dark' ? styles.ionInputDark : styles.ionInputLight}`}>
                                    <img className={styles.labelIcon} src={'/assets/label-icon/2@2x.png'}/>
                                    <img className={styles.countryIcon} onClick={() => openDialog(true)} src={codeData.iconUrl || '/assets/country.png'}
                                         alt=""/>
                                    <img className={styles.downArrowIcon} src={'/assets/images/down-arrow.png'}/>
                                    <div className={styles.divider}>|</div>
                                    <div className={styles.areaCode}>+{codeData.areaCode}</div>
                                    <IonInput
                                        className={`${styles.commonInput} ${themeValue === 'dark' ? styles.ionInputDark : styles.ionInputLight}`}
                                        type={'number'}
                                        value={loginName}
                                        placeholder={t('signup.phone')}
                                        onIonChange={e => setLoginName(e.detail.value)}
                                        clearInput
                                    />
                                </div> :
                                <div className={`${styles.formItem} ${themeValue === 'dark' ? styles.ionInputDark : styles.ionInputLight}`} style={{marginTop: '15px'}}>
                                    <img className={styles.labelIcon} src={'/assets/label-icon/email@2x.png'}/>
                                    <IonInput
                                        className={`${styles.commonInput} ${themeValue === 'dark' ? styles.ionInputDark : styles.ionInputLight}`}
                                        value={email}
                                        placeholder={t('common-login-reg.enter-email')}
                                        onIonChange={e => setEmail(e.detail.value)}
                                        clearInput
                                        type='text'
                                    />
                                </div>
                            }
                            <div className={`${styles.formItem} ${themeValue === 'dark' ? styles.ionInputDark : styles.ionInputLight}`} style={{marginTop: '15px'}}>
                                <img className={styles.labelIcon} src={'/assets/label-icon/3@2x.png'}/>
                                <IonInput
                                    className={`${styles.commonInput} ${themeValue === 'dark' ? styles.ionInputDark : styles.ionInputLight}`}
                                    value={loginPassword}
                                    placeholder={t('signup.password')}
                                    onIonChange={e => setLoginPassword(e.detail.value)}
                                    clearInput
                                    type='password'
                                />
                            </div>

                            <IonRow className={`ion-padding-bottom ion-padding-start ion-padding-end ion-justify-content-between ${styles.loginCustomRow}`} />

                            <IonButton className={`ion-margin-top ion-margin-bottom ${themeValue === 'dark' ? styles.loginSubmitBtnDark : styles.loginSubmitBtnLight}`} expand="block" onClick={login}>
                                {t('login.login')}
                            </IonButton>

                            <IonRow className="ion-justify-content-center">
                                <IonRouterLink routerLink="/signup" className={`${styles.goToSignup} ${themeValue === 'dark' ? styles.goToSignupDark : styles.goToSignupLight}`}>
                                    <p style={{textDecoration: 'underline'}}>{t('login.register')}</p>
                                </IonRouterLink>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>

            {showCustomerService &&
                <IonRouterLink routerLink='/support' className={styles.service2}>
                    <img src="assets/images/service2.png" alt=""/>
                </IonRouterLink>
            }
            <BottomLine/>
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => {setShowToast(false)}}
                message="Login success"
                duration={1000}
                position={'top'}
                color="primary"
            />
            <IonToast
                isOpen={showToast2}
                onDidDismiss={() => {setShowToast2(false)}}
                message={message}
                duration={1000}
                position={'top'}
                color="danger"
            />
        </IonPage>
    )
}

export default Login
