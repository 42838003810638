import { IonInput, IonLabel } from '@ionic/react'
import styles from './SignupField.module.scss'
import {useEffect, useState} from "react";
import { useIonRouter } from "@ionic/react"
import {
	channelIdKey,
	defaultTheme,
	showWithdrawFreeRateChannelIds,
	themeKey,
	withdrawFreeRateKey,
	modifySafePasswordNeedAuthChannelIds
} from "../../data/constant";
import {InfoApi} from '../../request/api'

const SignupField = ({ field, errors }) => {

	const router = useIonRouter()

	const error = errors && errors.filter(e => e.id === field.id)[0]
	const errorMessage = error && errors.filter(e => e.id === field.id)[0].message

	const [showWithdrawFee, setShowWithdrawFee] = useState(false)

	const [member, setMember] = useState({})
	const [hasSafePassword, setHasSafePassword] = useState(false)
	const [modifySafePasswordNeedAuth, setModifySafePasswordNeedAuth] = useState(false)

	const themeValue = localStorage.getItem(themeKey) || defaultTheme

	useEffect(() => {
		setShowWithdrawFeeTip()
		isModifySafePasswordNeedAuth()
		InfoApi().then(res => {
			// console.log(res)
			if (res.code === 200) {
				const { member } = res.data
				console.log(res.data);
				setMember(member)
				const { hasSafePassword } = res.data.member
				hasSafePassword && setHasSafePassword(hasSafePassword)

			}
		})
	}, [])

	const setShowWithdrawFeeTip = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (showWithdrawFreeRateChannelIds.indexOf(Number(channelId)) > -1) {
			setShowWithdrawFee(true)
		} else {
			setShowWithdrawFee(false)
		}
	}

	const isModifySafePasswordNeedAuth = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (modifySafePasswordNeedAuthChannelIds.indexOf(Number(channelId)) > -1) {
			setModifySafePasswordNeedAuth(true)
		} else {
			setModifySafePasswordNeedAuth(false)
		}
	}

	const actOnFocus = () => {
		if (modifySafePasswordNeedAuth && !hasSafePassword) {
			router.push('/support')
		}
	}

	return (
		<div className={styles.field}>
			<IonLabel className={`${styles.fieldLabel} ${themeValue === 'dark' ? styles.ionLabelDark : styles.ionLabelLight}`}>
				{field.label}
				{error && <p className="animate__animated animate__bounceIn">{errorMessage}</p>}
			</IonLabel>
			<IonInput onFocus={actOnFocus} className={`${styles.customInput} ${themeValue === 'dark' ? styles.ionInputDark : styles.ionInputLight}`} {...field.input.props} {...field.input.state} />
			{showWithdrawFee && field.input.props.feeText && <div className={styles.tip}>{field.input.props.feeText}: {localStorage.getItem(withdrawFreeRateKey)}%</div>}
		</div>
	)
}

export default SignupField
