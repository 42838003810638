import React, {useState} from 'react';
import styles from "../support/Support.module.scss";
import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonPage,
    IonRow,
    IonToolbar
} from "@ionic/react";
import {chevronBackOutline} from "ionicons/icons";
import {useLocation} from "react-router";
import { useHistory } from 'react-router'
import {useTranslation} from "react-i18next";
import qs from "qs";

const HtmlPage = () => {

    const { t, i18n } = useTranslation('lang')
    const history = useHistory()
    const location = useLocation()
    const paramStr = location.search.slice(1)
    const paramJson = qs.parse(paramStr)

    let chatUrl = paramJson.url

    if (paramJson.lang) {
        chatUrl = chatUrl + '&lang=' + paramJson.lang
    }

    if (paramJson.language) {
        chatUrl = chatUrl + '&language=' + paramJson.language
    }

    if (paramJson.userInfo) {
        chatUrl = chatUrl + '&userInfo=' + paramJson.userInfo
    }

    const [iFrameHeight, setIFrameHeight] = useState(document.documentElement.clientHeight - 20 + 'px')

    const goBack = () => {
        history.goBack()
    }

    return (
        <IonPage className={styles.recordPage}>
            <IonHeader>
                <IonToolbar>
                    <IonRow className="ion-justify-content-between ion-align-items-center">
                        <IonCol size="2">
                            <IonButtons>
                                <IonButton onclick={() => goBack()}>
                                    <IonIcon slot={'icon-only'} icon={chevronBackOutline}/>
                                </IonButton>
                            </IonButtons>
                        </IonCol>
                        <IonCol size="8">
                            <h4 className="main-title ion-text-center ion-no-margin">{t('support.title')}</h4>
                        </IonCol>
                        <IonCol size="2"/>
                    </IonRow>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="ion-no-padding">
                <IonGrid className="ion-padding-start ion-padding-end ion-padding-bottom">
                    <iframe scrolling="yes" frameBorder="0"
                             style={{width: '100%', height: iFrameHeight, overflow:'visible'}}
                             src={chatUrl}
                    />
                </IonGrid>
            </IonContent>
        </IonPage>
    )
}

export default HtmlPage
