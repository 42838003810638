import {
	IonButton,
	IonButtons,
	IonBackButton,
	IonCol,
	IonGrid,
	IonHeader,
	IonPage,
	IonRow,
	IonToolbar,
	IonImg,
	IonInput,
	IonList,
	IonToast,
	IonContent, IonIcon
} from '@ionic/react'
import styles from './Info.module.scss'

import { chevronBackOutline } from 'ionicons/icons'
import { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router'

import { useTranslation } from 'react-i18next'

import { InfoApi, InfoModifyApi, ModifyAvatarApi } from '../../request/api'
import BottomLine from '../../components/bottom-line/BottomLine'
import {
	channelIdKey, defaultTheme,
	hideInvitationCodeKey,
	hideInviteCodeChannelIds,
	logoUrlKey,
	notAllowedModifyProfileKey, themeKey
} from "../../data/constant";
import {useRaceConditions} from "../../hooks";
import {changeToStar} from "../../data/utils";

const Info = () => {
	const history = useHistory()
	const { t, i18n } = useTranslation('lang')
	const [id, setId] = useState('')
	const [username, setUsername] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [invitationCode, setInvitationCode] = useState('')
	const [nickName, setNickName] = useState('')
	const [email, setEmail] = useState('')
	const [imgUrl, setImgUrl] = useState(localStorage.getItem(logoUrlKey))

	const [showToast, setShowToast] = useState(false)
	const [showToast2, setShowToast2] = useState(false)
	const [message, setMessage] = useState('')

	const [showInviteCode, setShowInviteCode] = useState(true)

	const themeValue = localStorage.getItem(themeKey) || defaultTheme

	useRaceConditions(() => {
		isCanShowInviteCode()
	}, [])

	const changeInfoAction = () => {
		let reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
		if (!reg.test(email)) {
			alert('邮箱错误！')
			return
		}
		let data = {
			nickName,
			email
		}
		InfoModifyApi(data).then(res => {
			if (res.code === 200) {
				setShowToast(true)
			} else {
				setMessage(res.msg)
				setShowToast2(true)
			}
		})
	}

	const uploadImg = () => {
		//处理图片
		const fd = new FormData()
		fd.set('file', document.getElementById('uploadFile').files[0])
		ModifyAvatarApi(fd).then(res => {
			// console.log(res)
			if (res.code === 200) {
				setImgUrl(res.avatar)
			}
		})
	}

	useEffect(() => {
		if (localStorage.getItem('Authorization') == null) history.push('/login')
		InfoApi().then(res => {
			if (res.code === 200) {
				// console.log('res.data.member', res.data.member)
				const { id, memberName, phoneNumber, inviteCode, nickName, email, avatar } = res.data.member
				setId(id)
				setUsername(memberName)
				if (phoneNumber) {
					setPhoneNumber(changeToStar(phoneNumber, 2, 2))
				} else {
					setPhoneNumber(phoneNumber)
				}
				setInvitationCode(inviteCode)
				setNickName(nickName)
				setEmail(email)
				if (avatar) {
					setImgUrl(avatar)
				}
				// console.log(imgUrl)
			}
		})
	}, [])

	const isCanShowInviteCode = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (hideInviteCodeChannelIds.indexOf(Number(channelId)) > -1) {
			setShowInviteCode(false)
		} else {
			setShowInviteCode(true)
		}
	}

	const goBack = () => {
		history.goBack()
	}

	return (
		<IonPage className={styles.withdrawPage}>
			<IonHeader className={`${themeValue === 'dark' ? styles.headerDark : styles.headerLight}`}>
				<IonToolbar>
					<IonRow className="ion-justify-content-between ion-align-items-center">
						<IonCol size="2">
							<IonButtons>
								<IonButton onclick={() => goBack()}>
									<IonIcon style={{color: themeValue === 'dark' ? 'white' :'black'}} slot={'icon-only'} icon={chevronBackOutline}/>
								</IonButton>
							</IonButtons>
						</IonCol>
						<IonCol size="8">
							<h4 className={`main-title ion-text-center ion-no-margin ${themeValue === 'dark' ? styles.headerTitleDark : styles.headerTitleLight}`}>{t('change-info.title')}</h4>
						</IonCol>
						<IonCol size="2"/>
					</IonRow>
				</IonToolbar>
			</IonHeader>
			<IonContent>

				<div className={`${styles.outAvatar} ${themeValue === 'dark' ? styles.outAvatarDark : styles.outAvatarLight}`}>
					<IonImg src={imgUrl} alt="avatar" className={styles.avatar} />
					<input type="file" id="uploadFile" onChange={uploadImg} styles={{display: 'none'}}/>
				</div>
				<IonList className={`${styles.list1} ${themeValue === 'dark' ? styles.listDark : styles.listLight}`}>
					<IonInput readonly value={id}>
						<div>ID:</div>
					</IonInput>

					<IonInput readonly value={username}>
						<div>{t('common-text.user-name')}:</div>
					</IonInput>

					<IonInput readonly value={phoneNumber}>
						<div>{t('common-text.phone-number')}:</div>
					</IonInput>

					{showInviteCode &&
						<IonInput readonly value={invitationCode}>
							<div>{t('common-text.invitation-code')}:</div>
						</IonInput>
					}
				</IonList>

				<IonList className={`${styles.list2} ${themeValue === 'dark' ? styles.listDark : styles.listLight}`}>
					<IonInput readonly={localStorage.getItem(notAllowedModifyProfileKey) === 'true'} value={nickName} onIonChange={e => setNickName(e.detail.value)} style={{borderBottom: '1px solid #ccc'}}>
						<div>{t('common-text.nick-name')}:</div>
					</IonInput>

					<IonInput readonly={localStorage.getItem(notAllowedModifyProfileKey) === 'true'} value={email} onIonChange={e => setEmail(e.detail.value)} style={{marginTop: '15px', borderBottom: '1px solid #ccc'}}>
						<div>{t('common-text.email')}:</div>
					</IonInput>
				</IonList>

				<div className="ion-padding d-flex ion-justify-content-center">
					<IonButton
						className={`custom-button ion-padding-top ion-margin-bottom ${themeValue === 'dark' ? styles.infoSubmitBtnDark : styles.infoSubmitBtnLight}`}
						expand="block"
						onClick={changeInfoAction}
					>
						{t('change-info.title')}
					</IonButton>
				</div>
			</IonContent>
			<BottomLine />
			<IonToast
				isOpen={showToast}
				onDidDismiss={() => {setShowToast(false)}}
				message="Modify successfully"
				duration={1000}
				color="primary"
				position={'top'}
			/>
			<IonToast
				isOpen={showToast2}
				onDidDismiss={() => {setShowToast2(false)}}
				message={message}
				duration={2000}
				color="danger"
				position={'top'}
			/>
		</IonPage>
	)
}

export default Info
