import React, { Component } from 'react'
import { createPortal } from 'react-dom'
import List from './country-list'
import styles from './DialogSide.module.scss'
import {defaultTheme, themeKey} from "../../data/constant";
export default class Dialog extends Component {
	constructor(props) {
		super(props)
		const doc = window.document
		this.node = doc.createElement('div')
		doc.body.appendChild(this.node)
	}
	componentWillUnmount() {
		window.document.body.removeChild(this.node)
	}
	render() {
		const { hideDialog, hide } = this.props
		let tem = hide ? 'hidden' : ''
		const themeValue = localStorage.getItem(themeKey) || defaultTheme
		// console.log('hide', tem)
		return createPortal(
			<div className={`${styles.dialogSideBox}`} style={{ visibility: tem }}>
				<div className={`dialog ${themeValue === 'dark' ? styles.dialogSideDark : styles.dialogSideLight}`}>
					<List themeValue={themeValue} hideDialog={hideDialog} title={this.props.title} data={this.props.data}></List>
				</div>
			</div>,
			this.node
		)
	}
}
