import { IonRow, IonLabel } from '@ionic/react'

import styles from './LanguageButton.module.scss'
import { useTranslation } from 'react-i18next'
import {defaultTheme, themeKey} from "../../data/constant";

const LanguageButton = ({ field, languageList, setLanguageList }) => {
	const { t, i18n } = useTranslation('lang')
	const themeValue = localStorage.getItem(themeKey) || defaultTheme

	return (
		<div
			className={`${styles.languageBox} ${themeValue === 'dark' ? styles.languageBoxDark : styles.languageBoxLight}`}
			onClick={() => {
				let langValue = field.dictValue
				if (langValue === 'ko-kr') {
					langValue = 'kor'
				} else if (langValue === 'zh-Hans') {
					langValue = 'zh'
				} else if (langValue === 'zh-Hant') {
					langValue = 'zht'
				}
				i18n.changeLanguage(langValue).then(r => {
				})
				localStorage.setItem('language-ind', field.dictValue)
				window.history.go(-1)
			}}
		>
			<IonRow
				className="ion-align-items-center"
				style={{ paddingLeft: '20px', paddingRight: '10px', display: 'flex' }}
			>
				<img src={'./assets/flags/' + field.dictValue + '.png'} style={{ width: '40px', margin: '0 10px 0 -20px' }} />
				<IonLabel>{field.dictLabel}</IonLabel>
			</IonRow>
		</div>
	)
}

export default LanguageButton
