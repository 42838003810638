import React from 'react'
import {
	IonCard,
	IonCol,
	IonContent,
	IonHeader,
	IonImg,
	IonPage,
	IonRow,
	IonToolbar,
	IonGrid,
	IonButtons,
	IonButton, IonIcon, IonToast
} from '@ionic/react'
import styles from './Support.module.scss'
import { chevronBackOutline } from 'ionicons/icons'

import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import {InfoApi, ServiceApi} from '../../request/api'
import { useHistory } from 'react-router'
import {compareTime, formatTimeByTimezoneWithHms, getTimeByDay, formatTimeByTimezoneWithDay, getIsApp, getUTC8Date, judgeClient} from "../../data/utils";
import {
	appTimeZoneKey,
	channelIdKey, defaultTheme,
	hideCustomerServiceWorkTimeIds, themeKey,
	useIChatChannelIds
} from "../../data/constant";
import {useRaceConditions} from "../../hooks";
const Support = () => {
	const { t, i18n } = useTranslation('lang')
	const history = useHistory()
	const [serviceList, setServiceList] = useState([])

	const [appTimeZone, setAppTimeZone] = useState('')

	const [showToast, setShowToast] = useState(false)
	const [message, setMessage] = useState('')
	const [memberInfo, setMemberInfo] = useState({})
	const [showCustomerServiceWorkTime, setShowCustomerServiceWorkTime] = useState(true)

	const themeValue = localStorage.getItem(themeKey) || defaultTheme

	useEffect(() => {
		setAppTimeZone(localStorage.getItem(appTimeZoneKey))
		if (localStorage.getItem('Authorization')) getMemberInfo()
		ServiceApi().then(res => {
			if (res.code === 200) {
				const { data } = res
				let lang = localStorage.getItem('language-ind')
				if (!lang) lang = 'vie'
				data.map(item => {
					const serviceNameJson = JSON.parse(item.serviceName)
					item.serviceName = serviceNameJson[lang]
					if (item.workBeginTime && item.workEndTime) {
						let workBeginTimeArr = item.workBeginTime.split(' ')
						item.workBeginTime = workBeginTimeArr[1]
						let workEndTimeArr = item.workEndTime.split(' ')
						item.workEndTime = workEndTimeArr[1]
					}
					return item
				})
				setServiceList(res.data)
			}
		})
	}, [])

	useRaceConditions(() => {
		isShowCustomerServiceWorkTime()
	}, [])

	const getMemberInfo = () => {
		InfoApi().then(res => {
			if (res.code === 200) {
				const { member } = res.data
				setMemberInfo(member)
			}
		})
	}

	const isShowCustomerServiceWorkTime = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (hideCustomerServiceWorkTimeIds.indexOf(Number(channelId)) > -1) {
			setShowCustomerServiceWorkTime(false)
		} else {
			setShowCustomerServiceWorkTime(true)
		}
	}

	const goBack = () => {
		//history.goBack()
		window.history.back(-2);
	}

	return (
		<IonPage className={styles.recordPage}>
			<IonHeader className={`${themeValue === 'dark' ? styles.headerDark : styles.headerLight}`}>
				<IonToolbar>
					<IonRow className="ion-justify-content-between ion-align-items-center">
						<IonCol size="2">
							<IonButtons>
								<IonButton onclick={() => goBack()}>
									<IonIcon style={{color: themeValue === 'dark' ? 'white' :'black'}} slot={'icon-only'} icon={chevronBackOutline}/>
								</IonButton>
							</IonButtons>
						</IonCol>
						<IonCol size="8">
							<h4 className={`main-title ion-text-center ion-no-margin ${themeValue === 'dark' ? styles.headerTitleDark : styles.headerTitleLight}`}>{t('support.title')}</h4>
						</IonCol>
						<IonCol size="2"/>
					</IonRow>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen className="ion-no-padding">
				<IonGrid className={`ion-padding-start ion-padding-end ion-padding-bottom ${themeValue === 'dark' ? styles.gridDark : styles.gridLight}`}>
					<IonImg className={styles.topPic} src="/assets/images/service5.png" />
					<p className={`text-white ion-padding-start ${styles.supportTip}`}>{t('support.tip')}</p>
					{ <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
						{serviceList.map((item, index) => {
							return (
								<IonCard
									className={`ion-no-margin ion-padding main-radius ${themeValue === 'dark' ? styles.ionCardDark : styles.ionCardLight}`}
									style={{ margin: '10px' }}
									key={index}
									onClick={() => {
										//window.open(`${item.hrefUrl}`, '_blank')
										// 检测是否在工作时间内
										const currentTimeOfHms = formatTimeByTimezoneWithHms(getUTC8Date())
										const workBeginHour = item.workBeginTime.split(":")[0];
										const workEndHour = item.workEndTime.split(":")[0];
										if (Number(workEndHour) <=  Number(workBeginHour)) {
											let getTimezoneWithDay = formatTimeByTimezoneWithDay(getUTC8Date());
											let getDateDay = getTimeByDay();
											if (!(Number(workBeginHour) <= Number(currentTimeOfHms.split(":")[0]) && getTimezoneWithDay <= getDateDay)) {
												setMessage(t('support.no_working'))
												setShowToast(true)
												return
											}
											if (!(Number(workEndHour) > Number(currentTimeOfHms.split(":")[0]) && getTimezoneWithDay === getDateDay)) {
												setMessage(t('support.no_working'))
												setShowToast(true)
												return
											}
										} else {
											if (compareTime(currentTimeOfHms, item.workBeginTime)) {
												setMessage(t('support.no_working'))
												setShowToast(true)
												return
											}
											if (compareTime(item.workEndTime, currentTimeOfHms)) {
												setMessage(t('support.no_working'))
												setShowToast(true)
												return
											}
										}
										
										// 组装用户信息
										let userInfo = {}
										if (useIChatChannelIds.indexOf(memberInfo.channelId) > -1) {
											if (memberInfo.id) {
												userInfo.uid = memberInfo.id
												userInfo.nickname = memberInfo.nickName
												userInfo.name = memberInfo.memberName
												userInfo.tel = memberInfo.phoneNumber
												userInfo.email = memberInfo.email

												if (memberInfo.channelId === 40) {
													userInfo.page = window.location.protocol + '//' + window.location.hostname
												}

												let jumpUrl;
												// if (Number(memberInfo.channelId) === 25) {
												// 	jumpUrl = item.hrefUrl + '&userInfo=' + JSON.stringify(encodeURIComponent(userInfo))
												// } else {
												// 	jumpUrl = item.hrefUrl + '&userInfo=' + JSON.stringify(userInfo)
												// }

												jumpUrl = item.hrefUrl + '&userInfo=' + JSON.stringify(userInfo)

												if (judgeClient() === 'Android' && !getIsApp()) {
													window.location.href = jumpUrl
												} else {
													history.push('/html-page?url=' + jumpUrl)
												}
											} else {
												if (judgeClient() === 'Android' && !getIsApp()) {
													window.location.href = item.hrefUrl
												} else {
													history.push('/html-page?url=' + item.hrefUrl)
												}
											}
										} else {
											if (judgeClient() === 'Android' && !getIsApp()) {
												window.location.href = item.hrefUrl
											} else {
												history.push('/html-page?url=' + item.hrefUrl)
											}
										}
									}}
								>
									<div className="ion-justify-content-between ion-align-items-center">
										<p style={{fontSize: '14px'}}>{item.serviceName}</p>
										{showCustomerServiceWorkTime &&
											<div>
												<p>{t('common-text.workTimes')}:</p>
												<p style={{fontSize: '12px', color: '#d8d8d8'}}>({appTimeZone}): {item.workBeginTime} ~ {item.workEndTime}</p>
											</div>
										}
										<img className={styles.csPic} src={item.iconUrl} alt="" />
									</div>
								</IonCard>
							)
						})}
					</div> }
				</IonGrid>
			</IonContent>
			<IonToast
				isOpen={showToast}
				onDidDismiss={() => {setShowToast(false)}}
				message={message}
				duration={2000}
				position={'middle'}
				color="danger"
			/>
		</IonPage>
	)
}

export default Support
