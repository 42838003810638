import styles from './Tabs.module.scss'
import { IonRouterOutlet, IonImg, IonTabBar, IonTabButton, IonTabs, IonLabel, IonRouterLink } from '@ionic/react'
import { Route } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import Home from '../home/Home'
import Order from '../order/Order'
import Record from '../record/Record'
import Support from '../support/Support'
import Account from '../account/Account'
import {useEffect, useState} from "react";
import {BaseImageApi} from "../../request/api";
import {defaultTheme, themeKey} from "../../data/constant";

const Tabs = () => {
	const { t, i18n } = useTranslation('lang')
	const [tabBarsOfMiddleIcon, setTabBarsOfMiddleIcon] = useState('assets/images/home/9@2x.png')
	const themeValue = localStorage.getItem(themeKey) || defaultTheme

	useEffect(() => {
		BaseImageApi().then(res => {
			if (res.code === 200) {
				if (res.data.length > 0) {
					for (let info of res.data) {
						if (info.obType === '9') {
							setTabBarsOfMiddleIcon(info.imgUrls)
							break;
						}
					}
				}

			}
		})
	}, [])

	return (
		<IonTabs className={`${styles.tabsBox}`}>
			<IonRouterOutlet>
				<Route exact path="/app/home">
					<Home />
				</Route>

				<Route exact path="/app/order">
					<Order />
				</Route>

				<Route exact path="/app/record">
					<Record />
				</Route>

				<Route exact path="/app/account">
					<Account />
				</Route>

				<Route exact path="/support">
					<Support />
				</Route>
			</IonRouterOutlet>
			<IonTabBar slot="bottom">
				<IonTabButton tab="home" href="/app/home" className="navigation-tab" style={{ transform: 'translateY(8px)' }}>
					{window.location.href.indexOf('/app/home') !== -1 ? (
						<>
							<IonImg src="assets/images/home/1@2x.png" alt="home" />
							<IonLabel className={`${styles.tabLabel} `}>{t('tabs.home')}</IonLabel>
						</>
					) : (
						<>
							<IonImg src="assets/images/home/2@2x.png" alt="home" />
							<IonLabel className={styles.tabLabel}>{t('tabs.home')}</IonLabel>
						</>
					)}
				</IonTabButton>
				<IonTabButton
					tab="record"
					href="/app/record"
					className="navigation-tab"
					style={{ transform: 'translateY(8px)' }}
				>
					{window.location.href.indexOf('/app/record') !== -1 ? (
						<>
							<IonImg src="assets/images/home/3@2x.png" alt="record" />
							<IonLabel className={styles.tabLabel}>{t('tabs.record')}</IonLabel>
						</>
					) : (
						<>
							<IonImg src="assets/images/home/4@2x.png" alt="record" />
							<IonLabel className={styles.tabLabel}>{t('tabs.record')}</IonLabel>
						</>
					)}
				</IonTabButton>
				<IonTabButton tab="order" href="/app/order" className="navigation-tab">
					<IonImg className={styles.tabMainButton + ' home-icon'} src={tabBarsOfMiddleIcon} alt="order" />
				</IonTabButton>
				<IonTabButton
					tab="support"
					// href="/support"
					className="navigation-tab"
					style={{ transform: 'translateY(8px)' }}
				>
					<IonRouterLink routerLink='/support' className={styles.tabItemBox}>
						<IonImg src="assets/images/home/6@2x.png" alt="service" />
						<IonLabel>{t('tabs.service')}</IonLabel>
					</IonRouterLink>
					{/* {window.location.href.indexOf('/support') != -1 ? (
						<>
							<IonImg src="assets/images/home/5@2x.png" alt="service" />
							<IonLabel>{t('tabs.service')}</IonLabel>
						</>
					) : (
						<>
							<IonImg src="assets/images/home/6@2x.png" alt="service" />
							<IonLabel>{t('tabs.service')}</IonLabel>
						</>
					)} */}
				</IonTabButton>
				<IonTabButton
					tab="account"
					href="/app/account"
					className="navigation-tab"
					style={{ transform: 'translateY(8px)' }}
				>
					{window.location.href.indexOf('/app/account') !== -1 ? (
						<>
							<IonImg src="assets/images/home/7@2x.png" alt="account" />
							<IonLabel className={styles.tabLabel}>{t('tabs.account')}</IonLabel>
						</>
					) : (
						<>
							<IonImg src="assets/images/home/8@2x.png" alt="account" />
							<IonLabel className={styles.tabLabel}>{t('tabs.account')}</IonLabel>
						</>
					)}
				</IonTabButton>
			</IonTabBar>
		</IonTabs>
	)
}

export default Tabs
