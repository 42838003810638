import i18next from 'i18next'
import lang_en from './translations/en/lang.json'
import lang_zh_Hans from './translations/zh-Hans/lang.json'
import lang_zh_Hant from './translations/zh-Hant/lang.json'
import lang_ind from './translations/ind/lang.json'
import lang_vie from './translations/vie/lang.json'
import lang_vie_0 from './translations/vie/lang.json'
import lang_vie_1 from './translations/vie/lang-1.json'
import lang_vie_2 from './translations/vie/lang-2.json'
import lang_vie_3 from './translations/vie/lang-3.json'
import lang_vie_5 from './translations/vie/lang-5.json'
import lang_vie_6 from './translations/vie/lang-6.json'
import lang_vie_7 from './translations/vie/lang-7.json'
import lang_vie_8 from './translations/vie/lang-8.json'
import lang_vie_9 from './translations/vie/lang-9.json'
import lang_vie_10 from './translations/vie/lang-10.json'
import lang_vie_11 from './translations/vie/lang-11.json'
import lang_vie_12 from './translations/vie/lang-12.json'
import lang_vie_13 from './translations/vie/lang-13.json'
import lang_vie_15 from './translations/vie/lang-15.json'
import lang_vie_16 from './translations/vie/lang-16.json'
import lang_vie_20 from './translations/vie/lang-20.json'
import lang_vie_23 from './translations/vie/lang-23.json'
import lang_vie_24 from './translations/vie/lang-24.json'
import lang_vie_25 from './translations/vie/lang-25.json'
import lang_vie_27 from './translations/vie/lang-27.json'
import lang_vie_28 from './translations/vie/lang-28.json'
import lang_vie_29 from './translations/vie/lang-29.json'
import lang_vie_30 from './translations/vie/lang-30.json'
import lang_vie_31 from './translations/vie/lang-31.json'
import lang_vie_32 from './translations/vie/lang-32.json'
import lang_vie_35 from './translations/vie/lang-35.json'
import lang_vie_36 from './translations/vie/lang-36.json'
import lang_vie_37 from './translations/vie/lang-37.json'
import lang_vie_39 from './translations/vie/lang-39.json'
import lang_vie_40 from './translations/vie/lang-40.json'
import lang_vie_41 from './translations/vie/lang-41.json'
import lang_jp from './translations/jp/lang.json'
import lang_ko_kr from './translations/ko-kr/lang.json'
import lang_es from './translations/es/lang.json'
import lang_pt from './translations/pt/lang.json'
import lang_in from './translations/in/lang.json'
import lang_lk from './translations/lk/lang.json'
import lang_th from './translations/th/lang.json'
import lang_fr from './translations/fr/lang.json'
import {ChannelApi} from "./request/api"
import {channelIdKey} from "./data/constant"

const value = localStorage.getItem('language-ind')

// 请求渠道号来区分文件

// 0 1 2 3 5 6 7 8 9 10 11 12 13 15

let lang_vie_tmp = null

ChannelApi().then(res => {
    if (res.code === 200) {
        localStorage.setItem(channelIdKey, res.channelId)
        switch (res.channelId) {
            case 0:
                lang_vie_tmp = lang_vie_0
                break;
            case 1:
                lang_vie_tmp = lang_vie_1
                break;
            case 2:
                lang_vie_tmp = lang_vie_2
                break;
            case 3:
                lang_vie_tmp = lang_vie_3
                break;
            case 5:
                lang_vie_tmp = lang_vie_5
                break;
            case 6:
                lang_vie_tmp = lang_vie_6
                break;
            case 7:
                lang_vie_tmp = lang_vie_7
                break;
            case 8:
                lang_vie_tmp = lang_vie_8
                break;
            case 9:
                lang_vie_tmp = lang_vie_9
                break;
            case 10:
                lang_vie_tmp = lang_vie_10
                break;
            case 11:
                lang_vie_tmp = lang_vie_11
                break;
            case 12:
                lang_vie_tmp = lang_vie_12
                break;
            case 13:
                lang_vie_tmp = lang_vie_13
                break;
            case 15:
                lang_vie_tmp = lang_vie_15
                break;
            case 16:
                lang_vie_tmp = lang_vie_16
                break;
            case 20:
                lang_vie_tmp = lang_vie_20
                break;
            case 23:
                lang_vie_tmp = lang_vie_23
                break;
            case 24:
                lang_vie_tmp = lang_vie_24
                break;
            case 25:
                lang_vie_tmp = lang_vie_25
                break;
            case 27:
                lang_vie_tmp = lang_vie_27
                break;
            case 28:
                lang_vie_tmp = lang_vie_28
                break;
            case 29:
                lang_vie_tmp = lang_vie_29
                break;
            case 30:
                lang_vie_tmp = lang_vie_30
                break;
            case 31:
                lang_vie_tmp = lang_vie_31
                break;
            case 32:
                lang_vie_tmp = lang_vie_32
                break;
            case 35:
                lang_vie_tmp = lang_vie_35
                break;
            case 36:
                lang_vie_tmp = lang_vie_36
                break;
            case 37:
                lang_vie_tmp = lang_vie_37
                break;
            case 39:
                lang_vie_tmp = lang_vie_39
                break;
            case 40:
                lang_vie_tmp = lang_vie_40
                break;
            case 41:
                lang_vie_tmp = lang_vie_41
                break;
            default:
                lang_vie_tmp = lang_vie
                break;
        }
        if (res.configs.length > 0) {
            for (let info of res.configs) {
                if (info.configKey === 'app.default.language') {
                    const value = localStorage.getItem('language-ind')
                    if (!value) {
                        localStorage.setItem('language-ind', info.configValue)
                    }
                    i18next.init({
                        interpolation: { escapeValue: false },
                        lng: value || info.configValue,
                        resources: {
                            en: {
                                lang: lang_en
                            },
                            zh: {
                                lang: lang_zh_Hans
                            },
                            zht: {
                                lang: lang_zh_Hant
                            },
                            vie: {
                                lang: lang_vie_tmp
                            },
                            ind: {
                                lang: lang_ind
                            },
                            kor: {
                                lang: lang_ko_kr
                            },
                            jp: {
                                lang: lang_jp
                            },
                            es: {
                                lang: lang_es
                            },
                            pt: {
                                lang: lang_pt
                            },
                            in: {
                                lang: lang_in
                            },
                            lk: {
                                lang: lang_lk
                            },
                            th: {
                                lang: lang_th
                            },
                            fr: {
                                lang: lang_fr
                            }
                        }
                    })
                }
            }
        }
    }
}).catch(r => {

})

i18next.init({
    interpolation: { escapeValue: false },
    lng: `${value ? value : 'en'}`,
    resources: {
        en: {
            lang: lang_en
        },
        zh: {
            lang: lang_zh_Hans
        },
        zht: {
            lang: lang_zh_Hant
        },
        vie: {
            lang: lang_vie_tmp
        },
        ind: {
            lang: lang_ind
        },
        kor: {
            lang: lang_ko_kr
        },
        jp: {
            lang: lang_jp
        },
        es: {
            lang: lang_es
        },
        pt: {
            lang: lang_pt
        },
        in: {
            lang: lang_in
        },
        lk: {
            lang: lang_lk
        },
        th: {
            lang: lang_th
        },
        fr: {
            lang: lang_fr
        }
    }
})

export default i18next;
