import { Component } from 'react'
import './BottomLine.scss'

export default class BottomLine extends Component {

	render() {
		return (
			<div className="bottom-line">

			</div>
		)
	}
}
