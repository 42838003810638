import {
	IonButtons,
	IonBackButton,
	IonCol,
	IonHeader,
	IonPage,
	IonRow,
	IonToolbar,
	IonContent,
	IonCard, IonButton, IonIcon,
} from '@ionic/react'
import styles from './CompanyRules.module.scss'

import { chevronBackOutline } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import BottomLine from '../../components/bottom-line/BottomLine'
import {useHistory, useLocation} from 'react-router'
import { useEffect, useState } from 'react'
import qs from "qs";
import {HomeIntroduceInfoApi, HomeRulesApi} from "../../request/api";
import {defaultTheme, themeKey} from "../../data/constant";

const CompanyRules = () => {
	const url = window.location.search

	const [introduceTitle, setIntroduceTitle] = useState([])
	const [introduceContent, setIntroduceContent] = useState([])

	const { t, i18n } = useTranslation('lang')
	const history = useHistory()
	const location = useLocation()

	const paramStr = location.search.slice(1)
	const paramJson = qs.parse(paramStr)

	const themeValue = localStorage.getItem(themeKey) || defaultTheme

	useEffect(() => {
		if (localStorage.getItem('Authorization') === null) history.push('/login')
	}, [])

	useEffect(() => {
		if (url) {
			HomeIntroduceInfoApi({id: paramJson.id}).then(res => {
				if (res.code === 200) {
					setIntroduceTitle(res.data.graphicTitle)
					setIntroduceContent(res.data.graphicContent)
				}
			})
		} else {
			HomeRulesApi().then(res => {
				if (res.code === 200) {
					// console.log(res.data[2].graphicTitle)
					if(res.data.length > 0) {
						for (let i = 0; i < res.data.length; i++) {
							if (res.data[i].graphicType === '6') {
								setIntroduceTitle(res.data[i].graphicTitle)
								setIntroduceContent(res.data[i].graphicContent)
								break
							}
						}

					}
				}
			})
		}
	}, [])

	const goBack = () => {
		history.goBack()
	}

	return (
		<IonPage className={styles.companyPage}>
			<IonHeader className={`${themeValue === 'dark' ? styles.headerDark : styles.headerLight}`}>
				<IonToolbar>
					<IonRow className="ion-justify-content-between ion-align-items-center">
						<IonCol size="2">
							<IonButtons>
								<IonButton onclick={() => goBack()}>
									<IonIcon style={{color: themeValue === 'dark' ? 'white' :'black'}} slot={'icon-only'} icon={chevronBackOutline}/>
								</IonButton>
							</IonButtons>
						</IonCol>
						<IonCol size="8">
							<h4 className={`main-title ion-text-center ion-no-margin ${themeValue === 'dark' ? styles.headerTitleDark : styles.headerTitleLight}`}>{introduceTitle}</h4>
						</IonCol>
						<IonCol size="2"/>
					</IonRow>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonCard className={`ion-padding main-radius ion-margin-start ion-margin-end ${themeValue === 'dark' ? styles.ionCardDark : styles.ionCardLight}`}>
					<span dangerouslySetInnerHTML={{ __html: introduceContent }}></span>
				</IonCard>
			</IonContent>
			<BottomLine />
		</IonPage>
	)
}

export default CompanyRules
