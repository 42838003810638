import {
	IonButtons,
	IonBackButton,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonPage,
	IonRow,
	IonToolbar, IonButton, IonIcon,
} from '@ionic/react'
import styles from './Language.module.scss'

import LanguageButton from '../../components/language/LanguageButton'
import { chevronBackOutline } from 'ionicons/icons'
import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { LangApi } from '../../request/api'
import BottomLine from '../../components/bottom-line/BottomLine'
import {useHistory} from "react-router";
import {
	channelIdKey, defaultTheme,
	specifyShowLanguagesByChannel20,
	specifyShowLanguagesByChannel23,
	specifyShowLanguagesByChannel26,
	themeKey
} from "../../data/constant";

const Language = () => {
	const { t, i18n } = useTranslation('lang')

	const [langList, setLangList] = useState([])
	const history = useHistory()

	const themeValue = localStorage.getItem(themeKey) || defaultTheme

	useEffect(() => {
		// if (localStorage.getItem('Authorization') === null) history.push('/login')
		LangApi().then(res => {
			// console.log(res)
			if (res.code === 200) {
				let langList = []
				let channelId = localStorage.getItem(channelIdKey)
				if (Number(channelId) === 20) {
					for (let item of res.data) {
						if (specifyShowLanguagesByChannel20.indexOf(item.dictValue) > -1) {
							langList.push(item)
						}
					}
				} if (Number(channelId) === 23) {
					for (let item of res.data) {
						if (specifyShowLanguagesByChannel23.indexOf(item.dictValue) > -1) {
							langList.push(item)
						}
					}
				} if (Number(channelId) === 26) {
					for (let item of res.data) {
						if (specifyShowLanguagesByChannel26.indexOf(item.dictValue) > -1) {
							langList.push(item)
						}
					}
				} else {
					langList = res.data
				}
				setLangList(langList)
			}
		})
	}, [])

	const goBack = () => {
		history.goBack()
	}

	return (
		<IonPage className={styles.languagePage}>
			<IonHeader className={`${themeValue === 'dark' ? styles.headerDark : styles.headerLight}`}>
				<IonToolbar>
					<IonRow className="ion-justify-content-between ion-align-items-center">
						<IonCol size="2">
							<IonButtons>
								<IonButton onclick={() => goBack()}>
									<IonIcon style={{color: themeValue === 'dark' ? 'white' :'black'}} slot={'icon-only'} icon={chevronBackOutline}/>
								</IonButton>
							</IonButtons>
						</IonCol>
						<IonCol size="8">
							<h4 className={`main-title ion-text-center ion-no-margin ${themeValue === 'dark' ? styles.headerTitleDark : styles.headerTitleLight}`}>{t('language.title')}</h4>
						</IonCol>
						<IonCol size="2"/>
					</IonRow>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen className="ion-padding">
				<IonGrid>
					<IonRow>
						<IonCol size="12">
							{langList.map((field, index) => {
								return <LanguageButton field={field} key={index} />
							})}
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
			<BottomLine />
		</IonPage>
	)
}

export default Language
