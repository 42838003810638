import {
	IonButton,
	IonButtons,
	IonImg,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonPage,
	IonRouterLink,
	IonRow,
	IonToolbar,
	IonToast,
	IonInput,
	useIonLoading, IonSegment, IonSegmentButton, IonLabel
} from '@ionic/react'
import styles from './Signup.module.scss'

import DialogSide from '../../components/dialog-side/DialogSide'
import {globeOutline} from 'ionicons/icons'
import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import {RegisterApi, AreaCodeInfoApi, AreaCodeApi, ChannelApi, BaseImageApi} from '../../request/api'

import { hex_md5 } from '../../data/md5'
import BottomLine from '../../components/bottom-line/BottomLine'
import {useHistory, useLocation} from "react-router";
import qs from "qs";
import {checkEmailFormat, setFavicon} from "../../data/utils";
import {
	appDownloadLinkKey,
	channelIdKey, defaultTheme,
	grabOrderAnimationTimeKey,
	hideAddTradeAccountBtnKey, hideDownloadAppChannelIds, hideEditTradeAccountBtnKey,
	logoUrlKey, themeKey, showQrcodeImgByRegPageChanneIds
} from "../../data/constant";
import i18next from "../../i18n";
import {useRaceConditions} from "../../hooks";

const Signup = () => {
	const { t, i18n } = useTranslation('lang')
	const location = useLocation()
	const { pathname } = useLocation()  // 存储当前路由地址 当路由变化时候 重新构建
	const [present, dismiss] = useIonLoading()
	const history = useHistory()

	const [showToast, setShowToast] = useState(false)
	const [showToast2, setShowToast2] = useState(false)
	const [message, setMessage] = useState('')

	const [fromType, setFromType] = useState(1)

	const [imgUrl, setImgUrl] = useState('')

	const [countries, setCountries] = useState([])

	const [codeData, setCodeData] = useState({
		areaCode: '84',
		iconUrl: ''
	})

	const paramStr = location.search.slice(1)
	const paramJson = qs.parse(paramStr)

	const [username, setUsername] = useState('')
	const [loginName, setLoginName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [inviteCode, setInviteCode] = useState(paramJson.code ? paramJson.code : '')

	const [showDialog, setShowDialog] = useState(false)

	const [showDownloadAppLink, setShowDownloadAppLink] = useState(false)

	const [showQrcodeImg, setShowQrcodeImg] = useState(false)

	const themeValue = localStorage.getItem(themeKey) || defaultTheme

	useRaceConditions(() => {
		
	}, [pathname])

	useEffect(() => {

		ChannelApi().then(res => {
			if (res.code === 200) {
				localStorage.setItem(channelIdKey, res.channelId)
				if (showQrcodeImgByRegPageChanneIds.indexOf(Number(res.channelId)) > -1) {
					setShowQrcodeImg(true)
				} else {
					setShowQrcodeImg(false)
				}
				if (hideDownloadAppChannelIds.indexOf(Number(res.channelId)) < 0) {
					setShowDownloadAppLink(true)
				}
				if (res.configs.length > 0) {
					for (let info of res.configs) {
						if (info.configKey === 'grab.order.animation.time') {
							localStorage.setItem(grabOrderAnimationTimeKey, info.configValue)
						}
						if (info.configKey === 'app.website.name') {
							document.title = info.configValue
						}
						if (info.configKey === 'app.download.link') {
							localStorage.setItem(appDownloadLinkKey, info.configValue)
						}
						if (info.configKey === 'hide.add.trade.account') {
							localStorage.setItem(hideAddTradeAccountBtnKey, info.configValue)
						}
						if (info.configKey === 'hide.edit.trade.account') {
							localStorage.setItem(hideEditTradeAccountBtnKey, info.configValue)
						}

						if (info.configKey === 'app.default.language') {
							const value = localStorage.getItem('language-ind')
							if (!value) {
								localStorage.setItem('language-ind', info.configValue)
							}
							i18next.changeLanguage(value || info.configValue).then(r => {})
						}

						if (info.configKey === 'app.default.area.code') {
							getAreaCodeInfo(info.configValue)
						}
					}
				}
				BaseImageApi().then(res => {
					if (res.code === 200) {
						if (res.data.length > 0) {
							for (let info of res.data) {
								if (info.obType === '2') {
									setImgUrl(info.imgUrls)
								}
								if (info.obType === '6') {
									setFavicon(info.imgUrls)
								}
							}
						}
					}
				})
			} else {
				localStorage.setItem(channelIdKey, null)
			}
		}).catch(r => {
			localStorage.setItem(channelIdKey, null)
		})
	}, [])

	const isCanShowQrcodeImg = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (showQrcodeImgByRegPageChanneIds.indexOf(Number(channelId)) > -1) {
			setShowQrcodeImg(true)
		} else {
			setShowQrcodeImg(false)
		}
	}

	const createAccount = () => {
		if (fromType === 2) {
			if (email) {
				if (!checkEmailFormat(email)) {
					setMessage(t('common-login-reg.email-format-error'))
					setShowToast2(true)
				}
			}
		}
		let data = {
			regType: fromType,
			memberName: username,
			areaCode: codeData.areaCode,
			phoneNumber: loginName || null,
			email: email || null,
			password: password ? hex_md5(password.trim()) : '',
			verifyPassword: confirmPassword ? hex_md5(confirmPassword.trim()) : '',
			inviteCode: inviteCode ? inviteCode.trim() : ''
		}
		RegisterApi(data).then(res => {
			if (res.code === 200) {
				setShowToast(true)
				history.replace('/login')
			} else {
				setMessage(res.msg)
				setShowToast2(true)
			}
		})
	}

	const openDialog = (status) => {
		present().then(_ => {})
		AreaCodeApi().then(res => {
			dismiss().then(_ => {})
			if (res.code === 200) {
				const { data } = res
				let lang = localStorage.getItem('language-ind')
				data.map(item => {
					let areaNameJson = JSON.parse(item.areaName)
					item.showName = areaNameJson[lang]
					return item
				})
				setCountries(data)
				setShowDialog(status)
			}
		})
	}

	const hideDialog = val => {
		if (!val) {
			setShowDialog(false)
			return
		}
		getAreaCodeInfo(val)
		setShowDialog(false)
	}

	const getAreaCodeInfo = (areaCode) => {
		AreaCodeInfoApi({areaCode: areaCode}).then(res => {
			if (res.code === 200) {
				const {areaCode, iconUrl} = res.data
				setCodeData({areaCode, iconUrl})
			}
		})
	}

	useEffect(() => {
		setImgUrl(localStorage.getItem(logoUrlKey))
	}, [])

	useEffect(() => {
		return () => {
			setUsername('')
			setLoginName('')
			setEmail('')
			setPassword('')
			setConfirmPassword('')
		}
	}, [])

	const toDownloadApp = () => {
		const appDownloadLink = localStorage.getItem(appDownloadLinkKey)
		if (appDownloadLink) {
			window.open(`${appDownloadLink}`, '_blank')
		}
	}

	const onChangeSegment = (e) => {
		setFromType(Number(e.detail.value))
	}

	return (
		<IonPage  className={`${styles.signupPage} ${themeValue === 'dark' ? styles.signupPageDark : styles.signupPageLight}`}>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start"/>
					<IonButtons slot="end">
						<IonRouterLink routerLink="/language">
							<IonButton>
								<IonIcon style={{color: themeValue === 'dark' ? 'white' :'black'}} icon={globeOutline} />
							</IonButton>
						</IonRouterLink>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			{showDialog && <DialogSide hideDialog={hideDialog} hide={false} title={t('common-text.area-code')} data={countries} />}
			<IonContent fullscreen className="ion-padding">
				<IonGrid className="ion-padding">
					<IonRow className="ion-justify-content-center">
						<IonImg src={imgUrl || '/assets/icon/logo.png'} />
					</IonRow>

					<IonRow style={{marginTop: '10px'}}>
						<IonSegment className={`${themeValue === 'dark' ? styles.ionSegmentDark : styles.ionSegmentLight}`} value={fromType} onIonChange={onChangeSegment}>
							<IonSegmentButton value="1">
								<IonLabel style={{color: themeValue === 'dark' ? fromType === 1 ? '#ffffff' : '#ccc' : fromType === 1 ? 'black' : '#ccc', fontSize: '15px'}}>{t('common-login-reg.login-type-1')}</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton value="2">
								<IonLabel style={{color: themeValue === 'dark' ? fromType === 2 ? '#ffffff' : '#ccc' : fromType === 2 ? 'black' : '#ccc', fontSize: '15px'}}>{t('common-login-reg.login-type-2')}</IonLabel>
							</IonSegmentButton>
						</IonSegment>
					</IonRow>

					<IonRow className="ion-margin-top ion-padding-top">
						<IonCol size="12">
							<div className={`${styles.formItem} ${themeValue === 'dark' ? styles.ionInputDark : styles.ionInputLight}`}>
								<img className={styles.labelIcon} src={'/assets/label-icon/1@2x.png'}/>
								<IonInput
									className={`${styles.commonInput} ${themeValue === 'dark' ? styles.ionInputDark : styles.ionInputLight}`}
									value={username}
									placeholder={t('signup.username')}
									onIonChange={e => setUsername(e.detail.value)}
									clearInput
									type='text'
								/>
							</div>
							{fromType === 1 ?
								<div className={`${styles.formItem} ${themeValue === 'dark' ? styles.ionInputDark : styles.ionInputLight}`} style={{marginTop: '15px'}}>
									<img className={styles.labelIcon} src={'/assets/label-icon/2@2x.png'}/>
									<img className={styles.countryIcon} onClick={() => openDialog(true)} src={codeData.iconUrl || '/assets/country.png'}
										 alt=""/>
									<img className={styles.downArrowIcon} src={'/assets/images/down-arrow.png'}/>
									<div className={styles.divider}>|</div>
									<div className={styles.areaCode}>+{codeData.areaCode}</div>
									<IonInput
										className={`${styles.commonInput} ${themeValue === 'dark' ? styles.ionInputDark : styles.ionInputLight}`}
										type={'number'}
										value={loginName}
										placeholder={t('signup.phone')}
										onIonChange={e => setLoginName(e.detail.value)}
										clearInput
									/>
								</div> :
								<div className={`${styles.formItem} ${themeValue === 'dark' ? styles.ionInputDark : styles.ionInputLight}`} style={{marginTop: '15px'}}>
									<img className={styles.labelIcon} src={'/assets/label-icon/email@2x.png'}/>
									<IonInput
										className={`${styles.commonInput} ${themeValue === 'dark' ? styles.ionInputDark : styles.ionInputLight}`}
										value={email}
										placeholder={t('common-login-reg.enter-email')}
										onIonChange={e => setEmail(e.detail.value)}
										clearInput
										type='text'
									/>
								</div>
							}

							<div className={`${styles.formItem} ${themeValue === 'dark' ? styles.ionInputDark : styles.ionInputLight}`} style={{marginTop: '15px'}}>
								<img className={styles.labelIcon} src={'/assets/label-icon/3@2x.png'}/>
								<IonInput
									className={`${styles.commonInput} ${themeValue === 'dark' ? styles.ionInputDark : styles.ionInputLight}`}
									value={password}
									placeholder={t('signup.password')}
									onIonChange={e => setPassword(e.detail.value)}
									clearInput
									type='password'
								/>
							</div>
							<div className={`${styles.formItem} ${themeValue === 'dark' ? styles.ionInputDark : styles.ionInputLight}`} style={{marginTop: '15px'}}>
								<img className={styles.labelIcon} src={'/assets/label-icon/4@2x.png'}/>
								<IonInput
									className={`${styles.commonInput} ${themeValue === 'dark' ? styles.ionInputDark : styles.ionInputLight}`}
									value={confirmPassword}
									placeholder={t('signup.cpassword')}
									onIonChange={e => setConfirmPassword(e.detail.value)}
									clearInput
									type='password'
								/>
							</div>
							<div className={`${styles.formItem} ${themeValue === 'dark' ? styles.ionInputDark : styles.ionInputLight}`} style={{marginTop: '15px'}}>
								<img className={styles.labelIcon} src={'/assets/label-icon/5@2x.png'}/>
								<IonInput
									className={`${styles.commonInput} ${themeValue === 'dark' ? styles.ionInputDark : styles.ionInputLight}`}
									value={inviteCode}
									placeholder={t('signup.invitation')}
									onIonChange={e => setInviteCode(e.detail.value)}
									clearInput
									type='text'
								/>
							</div>
							<IonButton
								className={`custom-button ion-padding-top ion-margin-bottom ${themeValue === 'dark' ? styles.regSubmitBtnDark : styles.regSubmitBtnLight}`}
								expand="block"
								onClick={createAccount}
							>
								{t('signup.register')}
							</IonButton>
						</IonCol>
					</IonRow>
					<IonRow className="ion-justify-content-center ion-padding-top">
						<p onClick={() => {history.replace('/login')}} style={{fontSize: '1rem', textDecoration: 'underline', color: themeValue === 'dark' ? 'white' : 'black'}}>{t('signup.login')}</p>
					</IonRow>
					{showDownloadAppLink &&
						<IonRow className="ion-justify-content-center">
							<p onClick={() => toDownloadApp()} style={{fontSize: '1rem', textDecoration: 'underline', color: themeValue === 'dark' ? 'white' : 'black'}} className="ion-no-margin">{t('signup.app')}</p>
						</IonRow>
					}
				</IonGrid>

				{
					showQrcodeImg && 
					<IonRow className="ion-justify-content-end" style={{marginRight: '10px'}}>
						<IonImg src='/assets/reg-qrcode.jpg'></IonImg>
					</IonRow>
				}
			</IonContent>
			<BottomLine />

			<IonToast
				isOpen={showToast}
				onDidDismiss={() => {setShowToast(false)}}
				message="Registered successfully"
				duration={1000}
				position={'top'}
				color="primary"
			/>
			<IonToast
				isOpen={showToast2}
				onDidDismiss={() => {setShowToast2(false)}}
				message={message}
				duration={2000}
				position={'top'}
				color="danger"
			/>
		</IonPage>
	)
}

export default Signup
