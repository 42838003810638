import styles from './Notification.module.scss'

import {
	IonButtons,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonPage,
	IonRow,
	IonToolbar,
	IonCard,
	IonIcon, IonButton
} from '@ionic/react'
import { chevronBackOutline, volumeHighOutline } from 'ionicons/icons'

import { useTranslation } from 'react-i18next'

import { MessageApi } from '../../request/api'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import {defaultTheme, themeKey} from "../../data/constant";

const Notification = () => {
	const { t, i18n } = useTranslation('lang')

	const [messageList, setMessageList] = useState([])
	const history = useHistory()

	const themeValue = localStorage.getItem(themeKey) || defaultTheme

	useEffect(() => {
		if (localStorage.getItem('Authorization') === null) history.push('/login')
		MessageApi().then(res => {
			// console.log(res)
			if (res.code === 200) {
				setMessageList(res.data)
			}
		})
	}, [])

	const goBack = () => {
		history.goBack()
	}

	return (
		<IonPage className={styles.notificationPage}>
			<IonHeader className={`${themeValue === 'dark' ? styles.headerDark : styles.headerLight}`}>
				<IonToolbar>
					<IonRow className="ion-justify-content-between ion-align-items-center">
						<IonCol size="2">
							<IonButtons>
								<IonButton onclick={() => goBack()}>
									<IonIcon style={{color: themeValue === 'dark' ? 'white' :'black'}} slot={'icon-only'} icon={chevronBackOutline}/>
								</IonButton>
							</IonButtons>
						</IonCol>
						<IonCol size="8">
							<h4 className={`main-title ion-text-center ion-no-margin ${themeValue === 'dark' ? styles.headerTitleDark : styles.headerTitleLight}`}>{t('system-info.title')}</h4>
						</IonCol>
						<IonCol size="2"></IonCol>
					</IonRow>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen className="ion-padding">
				<IonGrid className="ion-no-padding">
					<IonCard className="ion-no-margin ion-padding main-radius">
						<IonRow className="ion-align-items-center">
							<IonIcon icon={volumeHighOutline} />
							<span>{t('system-info.notification')}</span>
						</IonRow>
						{/*<p className="ion-text-center">{t('system-info.noti-title')}</p>*/}
						<span>{t('system-info.noti-content')}</span>
					</IonCard>
				</IonGrid>
			</IonContent>
		</IonPage>
	)
}

export default Notification
