import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import i18next from './i18n'
import { I18nextProvider } from 'react-i18next'
import {BaseImageApi, ChannelApi} from "./request/api";
import {
	appDefaultAreaCodeKey,
	appDownloadLinkKey, appTimeZoneKey,
	channelIdKey, defaultTheme,
	grabOrderAnimationTimeKey,
	hideAddTradeAccountBtnKey, hideEditTradeAccountBtnKey, hideInvitationCodeKey,
	logoUrlKey, notAllowedModifyProfileKey, themeKey, withdrawFreeRateKey
} from "./data/constant";
import {
	setFavicon,
	setBrowserTitle,
	getChanelIdByDomain
} from "./data/utils";

// 设置渠道ID
getChanelIdByDomain()

// 设置站点标题
setBrowserTitle()

var hasReqThemeValue = false

ChannelApi().then(res => {
	if (res.code === 200) {
		localStorage.setItem(channelIdKey, res.channelId)
		if (res.configs.length > 0) {
			for (let info of res.configs) {
				if (info.configKey === 'front.theme') {
					hasReqThemeValue = true
					let themeValue = localStorage.getItem(themeKey)
					if (info.configValue) {
						localStorage.setItem(themeKey, info.configValue)
					} else {
						localStorage.setItem(themeKey, defaultTheme)
					}

					// console.log("旧主题---" + themeValue)
					// console.log("新主题---" + localStorage.getItem(themeKey))

					// 设置主题颜色
					if (!localStorage.getItem(themeKey) || themeValue !== localStorage.getItem(themeKey)) {
						document.body.classList.toggle(localStorage.getItem(themeKey), true);
					}

					if (themeValue === localStorage.getItem(themeKey)) {
						document.body.classList.toggle(localStorage.getItem(themeKey), true);
					}
				}

				if (info.configKey === 'grab.order.animation.time') {
					localStorage.setItem(grabOrderAnimationTimeKey, info.configValue)
				}
				if (info.configKey === 'app.website.name') {
					document.title = info.configValue
				}
				if (info.configKey === 'app.download.link') {
					localStorage.setItem(appDownloadLinkKey, info.configValue)
				}
				if (info.configKey === 'system.time.zone') {
					localStorage.setItem(appTimeZoneKey, info.configValue)
				}
				if (info.configKey === 'hide.add.trade.account') {
					localStorage.setItem(hideAddTradeAccountBtnKey, info.configValue)
				}
				if (info.configKey === 'hide.edit.trade.account') {
					localStorage.setItem(hideEditTradeAccountBtnKey, info.configValue)
				}
				if (info.configKey === 'app.default.language') {
					const value = localStorage.getItem('language-ind')
					if (!value) {
						localStorage.setItem('language-ind', info.configValue)
					}
					i18next.changeLanguage(value || info.configValue).then(r => {})
				}

				if (info.configKey === 'app.default.area.code') {
					localStorage.setItem(appDefaultAreaCodeKey, info.configValue)
				}

				if (info.configKey === 'hide.invitation.code') {
					localStorage.setItem(hideInvitationCodeKey, info.configValue)
				}

				if (info.configKey === 'not.allowed.modify.profile') {
					localStorage.setItem(notAllowedModifyProfileKey, info.configValue)
				}

				if (info.configKey === 'withdraw.fee') {
					localStorage.setItem(withdrawFreeRateKey, info.configValue)
				}
			}
		}

		if (!localStorage.getItem(themeKey) || !hasReqThemeValue) {
			localStorage.setItem(themeKey, defaultTheme)
			document.body.classList.toggle(localStorage.getItem(themeKey), true);
		} else if (!hasReqThemeValue && localStorage.getItem(themeKey) ) {
			document.body.classList.toggle(localStorage.getItem(themeKey), true);
		}

		BaseImageApi().then(res => {
			if (res.code === 200) {
				if (res.data.length > 0) {
					for (let info of res.data) {
						if (info.obType === '2') {
							localStorage.setItem(logoUrlKey, info.imgUrls)
						}
						if (info.obType === '6') {
							setFavicon(info.imgUrls)
						}
					}
				}
			}
		})
	} else {
		localStorage.setItem(channelIdKey, null)
	}
}).catch(r => {
	localStorage.setItem(channelIdKey, null)
})

ReactDOM.render(
	<I18nextProvider i18n={i18next}>
		<App />
	</I18nextProvider>,
	document.getElementById('root')
)
