import styles from './MyAccount.module.scss'
import {
	IonButtons,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonPage,
	IonRow,
	IonToolbar,
	IonCard,
	IonRouterLink,
	IonButton, IonIcon
} from '@ionic/react'
import {chevronBackOutline, location} from 'ionicons/icons'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AccountListApi } from '../../request/api'
import {useHistory, useLocation} from 'react-router'
import {changeToStar} from "../../data/utils";
import {useRaceConditions} from "../../hooks";
import {defaultTheme, hideAddTradeAccountBtnKey, hideEditTradeAccountBtnKey, themeKey} from "../../data/constant";

const MyAccount = () => {
	const { t, i18n } = useTranslation('lang')
	const [accountArr, setAccountArr] = useState([])
	const history = useHistory()
	const { pathname } = useLocation()  // 存储当前路由地址 当路由变化时候 重新构建

	const [hideEditBtnState, setHideEditBtnState] = useState(localStorage.getItem(hideEditTradeAccountBtnKey))
	const [hideAddBtnState, setHideAddBtnState] = useState(localStorage.getItem(hideAddTradeAccountBtnKey))

	const themeValue = localStorage.getItem(themeKey) || defaultTheme

	useRaceConditions(() => {
		if (localStorage.getItem('Authorization') === null) history.push('/login')
		getAccountList()
	}, [pathname])

	const getAccountList = () => {
		AccountListApi().then(res => {
			if (res.code === 200) {
				let lang = localStorage.getItem('language-ind')
				if (!lang) lang = 'vie'
				const { data } = res
				data.map(item => {
					if (item.accountType === '1') {
						try {
							const bankNameJson = JSON.parse(item.bankName)
							item.showName = bankNameJson[lang]
						} catch (e) {
							item.showName = item.bankName
						}
					} else {
						item.showName = item.chainType
					}
					if (item.pix) item.pix = changeToStar(item.pix, 2, 2)
					if (item.address) item.address = changeToStar(item.address, 2, 2)
					return item
				})
				setAccountArr(res.data)
			}
		})
	}

	const goBack = () => {
		history.goBack()
	}

	return (
		<IonPage className={styles.rechargeRocordPage}>
			<IonHeader className={`${themeValue === 'dark' ? styles.headerDark : styles.headerLight}`}>
				<IonToolbar>
					<IonRow className="ion-justify-content-between ion-align-items-center">
						<IonCol size="2">
							<IonButtons>
								<IonButton onclick={() => goBack()}>
									<IonIcon style={{color: themeValue === 'dark' ? 'white' :'black'}} slot={'icon-only'} icon={chevronBackOutline}/>
								</IonButton>
							</IonButtons>
						</IonCol>
						<IonCol size="8">
							<h4 className={`main-title ion-text-center ion-no-margin ${themeValue === 'dark' ? styles.headerTitleDark : styles.headerTitleLight}`}>{t('my-account.title')}</h4>
						</IonCol>
						<IonCol size="2">
							{!hideAddBtnState || hideAddBtnState === 'false' ? <IonRouterLink routerLink="/bank">
								<h4 className={`${themeValue === 'dark' ? styles.headerTitleDark : styles.headerTitleLight}`}>{t('my-account.add')}</h4>
							</IonRouterLink> : null}
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen className="ion-padding">
				<IonGrid className="ion-no-padding">
					{accountArr.map((item, index) => {
						return (
							<IonCard
								key={index}
								className={`ion-no-margin ion-padding main-radius ${themeValue === 'dark' ? styles.ionCardDark : styles.ionCardLight}`}
							>
								<IonRow className="ion-justify-content-between ion-align-items-center">
									<span style={{ fontSize: '20px', color: '#fff' }}>
										{item.showName}
									</span>
									<span className={`${styles.statusBadge} ${themeValue === 'dark' ? styles.statusBadgeDark : styles.statusBadgeLight}`}>
										{!hideEditBtnState || hideEditBtnState === 'false' ? <IonRouterLink className={`${themeValue === 'dark' ? styles.linkTextDark : styles.linkTextLight}`} routerLink={'/bank?id=' + `${item.id}`} >
											{t('my-account.edit')}
										</IonRouterLink> : null}
									</span>
								</IonRow>
								<IonRow className="ion-justify-content-between ion-align-items-center" style={{ marginTop: '8px' }}>
									<span style={{ fontSize: '16px', color: '#fff' }}>
										{item.accountType === '1' ? item.pix : item.address}
									</span>
								</IonRow>
							</IonCard>
						)
					})}
				</IonGrid>
			</IonContent>
		</IonPage>
	)
}

export default MyAccount
